export const emailTemplates = {
    "Rep Introduction Version 1": (details) => `
        <div>
            <p>Dear ${details.rep},</p>
            <p>It’s nice to e-meet you! Medvelle was recently engaged by ${details.location} to manage their inventory and procurement. Importantly for you, nothing changes in relation to the ordering itself, with the small exception that order submissions will come from ${process.env.REACT_APP_PROCUREMENT_EMAIL}. We have copied ${details.owner} to this email for confirmation.</p>
            <p>On that note, ${details.location} would like to place an order for the following items—using a new payment method—and applying all current promotions and terms, as applicable:</p>
            <p>${details.orderTable}</p>
            <p>To make the ordering process as seamless as possible, we ask that you please update the email address for automatic order confirmation, invoice, and tracking updates to ${process.env.REACT_APP_NOREPLY_EMAIL}.</p>
            <p>Separately, we would also like to update the default payment method on file; when you have a moment, please give us a call at (212) …, and we can provide the details.</p>
            <p>Thank you in advance for your time and support. We look forward to doing business together.</p>
            <p>All the best,</p>
            <p>Medvelle Team</p>
        </div>`,

    "Rep Introduction Version 2": (details) => `
        <div>
            <p>Dear ${details.rep},</p>
            <p>Nice to e-meet you! Medvelle was recently engaged by ${details.location} to manage their inventory and procurement. Importantly for you, nothing changes in relation to the ordering itself, with the small exception that order submissions will come from ${process.env.REACT_APP_PROCUREMENT_EMAIL}. We have copied ${details.owner} to this email for confirmation.</p>
            <p>On that note, ${details.location} would like to place an order for the following items—using terms—and applying all current promotions, as applicable:</p>
            <p>${details.orderTable}</p>
            <p>To make the ordering process as seamless as possible, we ask that you please update the email address for automatic order confirmation, invoice, and tracking updates to ${process.env.REACT_APP_NOREPLY_EMAIL}.</p>
            <p>Thank you in advance for your time and support; I look forward to doing business together.</p>
            <p>All the best,</p>
            <p>Medvelle Team</p>
        </div>`,

    "Standard Order Version 1": (details) => `
        <div>
            <p>Dear ${details.rep},</p>
            <p>${details.location} would like to place the following order, using the card ending in *${details.card}:</p>
            <p>${details.orderTable}</p>
            <p>Please let us know if any adjustments to the order could favorably impact the pricing, and reply to this email once the order is confirmed.</p>
            <p>Thank you for your support,</p>
            <p>Medvelle Team</p>
        </div>`,

    "New Payment Method": (details) => `
        <div>
            <p>Dear ${details.rep},</p>
            <p>${details.location} would like to place the following order, using a new default payment method:</p>
            <p>${details.orderTable}</p>
            <p>We will be using a new default payment method for this account. When you have a moment, please give us a call at (212) … and we can provide the card details.</p>
            <p>Thank you for your support,</p>
            <p>Medvelle Team</p>
        </div>`,

    "Missing Information": (details) => `
        <div>
            <p>Hello ${details.rep},</p>
            <p>We hope this message finds you well. We are just following up regarding this past order for ${details.location}. Could you please forward the invoice and tracking number to ${process.env.REACT_APP_NOREPLY_EMAIL} when they become available? Additionally, would it be possible to set up automatic order updates (confirmation, invoice, tracking number) for this address going forward?</p>
            <p>As always, please feel free to contact us at (212)... if you have any questions, or if we can do anything else to support.</p>
            <p>Thank you for your support,</p>
            <p>Medvelle Team</p>
        </div>`,

    "Medvelle Rewards": (details) => `
        <div>
            <p>Hi ${details.user},</p>
            <p>We hope this email finds you well! We're excited to inform you that your Medvelle Coins have been successfully redeemed for ${details.orderItem}. Your order has been placed, and your prize will soon be on its way.</p>
            <p>Thank you for using SpotCheck, and congratulations!</p>
            <p>All the best,</p>
            <p>Medvelle Team</p>
        </div>`,

    "Text (Post-MVP) Version 1": (details) => `
        <div>
            <p>Hi ${details.rep}, it’s ${details.platformUser} from Medvelle, ${details.location}’s new procurement/inventory management partner. They’d like to place a ${details.supplier} order — would you mind giving me a call when you get the chance, so I can share the order details and card info? Thanks!</p>
        </div>`,

    "Text (Post-MVP) Version 2": (details) => `
        <div>
            <p>Hi ${details.rep}! The team just received a ${details.supplier} order from ${details.location}— we just emailed it over, but I just wanted to give you a heads up. Thanks!</p>
        </div>`,

    "Support Ticket": (details) => `
        <div>
            <p>Dear ${ details.user },</p>
            <p>This is an automated message confirming that we have received your support request with the following details:</p>
            <p>Category: ${ details.tag }</p>
            <p>Urgency: ${ details.ticket_urgency }</p>
            <p>Description: ${ details.ticket_description }</p>
            <p>Your support ticket is ${ details.ticket_id }.</p>
            <br>
            <p>We will get back to you as soon as possible.</p>
            <p>-Medvelle Team</p>
        </div>`,
    "Support Ticket Internal": (details) => `
        <div>
            <p>Dear ${ details.user },</p>
            <p>This is an automated message to inform you that we have opened a support ticket on your behalf with the following details:</p>
            <p>Category: ${ details.tag }</p>
            <p>Urgency: ${ details.ticket_urgency }</p>
            <p>Description: ${ details.ticket_description }</p>
            <p>Your support ticket is ${ details.ticket_id }.</p>
            <br>
            <p>We will provide an update as soon as possible.</p>
            <p>-Medvelle Team</p>
        </div>`,
};

export const tableOrders = (orders) => {
    return `
      <div style="border: 2px solid #87D3D7;
          width: 400px;
          box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);"
      >
        <table style="
                width: 400px;
                border-collapse: separate;
                border-spacing: 0;
                position: relative;"
        >
          <thead style="
                      top: 0;
                      z-index: 1;"
          >
            <tr style="font-size: 13px; background-color: white;">
                <th style="border-bottom: 2px solid black;
                          padding: 10px;
                          text-align: left;">Product Name</th>
                <th style="border-bottom: 2px solid black;
                          padding: 10px;
                          text-align: left;">Product Description</th>
                <th style="border-bottom: 2px solid black;
                          padding: 10px;
                          text-align: left;">Order Quantity</th>
            </tr>
          </thead>
          <tbody>
            ${orders.map((product, index) => `
                <tr key='${index}' style="font-size: 13px;
                                          background-color: ${index % 2 === 0 ? '#ebf4f5' : '#ffffff'};">
                    <td style="padding: 10px; text-align: left;">${product.product_name}</td>
                    <td style="padding: 10px;
                              text-align: left;">${product.product_description}</td>
                    <td style="padding: 10px;
                              text-align: left;">${product.order_quantity}</td>
                </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    `;
  };