import { fetchWithAuth } from '../authentication/authenticationUtils';
  
export const fetchOrders = async (call, locationName, setOrderData) => {
  try {
    const response = await fetchWithAuth(`${call}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name: locationName }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }

    const data = await response.json();
    setOrderData(data);
  } catch (error) {
    console.error('Error fetching orders:', error);
  }
};


export const getTrackingNumber = async (call, orderId) => {
  try {
    const response = await fetchWithAuth(`${call}/get-tracking-number`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ order_id: orderId }),
    });
    const data = await response.json();
    return data.trackingNumber;
  } catch (error) {
    console.error('Error fetching tracking number:', error);
    return null;
  }
};

export const fetchTrackingDataUrl = async (call, trackingNumberInput) => {
  try {
    const response = await fetchWithAuth(`${call}/get-tracking-order-data-url`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tracking_number: trackingNumberInput }),
    });
    const data = await response.json();
    if (response.ok) {
      return data
    } else {
        console.error('Error fetching tracking data:', data);
        return null;
    }
  } catch (error) {
    console.error('Error fetching tracking data:', error);
    return null;
  }
};

export const updateOrdersDeliveredManual = async (call, locationName, orderId, deliveredManualCheck) => {
  const oppositeDeliveredManualCheck = deliveredManualCheck ? 0 : 1;

  try {
    const response = await fetchWithAuth(`${call}/update-orders-delivered-manual`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name: locationName, order_id: orderId, delivered_manual_check: oppositeDeliveredManualCheck }),
    });

    if (!response.ok) {
      console.error('Failed to update delivered state.');
    }
  } catch (error) {
    console.error('Error updating delivered state:', error);
  }

};

export const updateOrderDetails = async (originalOrder, updatedOrder, username, call) => {
  try {
    const response = await fetchWithAuth(`${call}/edit-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderquantity: updatedOrder.order_quantity,
        orderurgency: updatedOrder.order_urgency,
        ordercomment: updatedOrder.client_comment,
        orderdata: originalOrder,
        username: username
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Order updated successfully:', data);
      return data;
    } else {
      console.error('Failed to update order');
      return null;
    }
  } catch (error) {
    console.error('Error updating order:', error);
    return null;
  }
};

export const removeOrder = async (originalOrder, username, call) => {
  try {
    const response = await fetchWithAuth(`${call}/delete-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderdata: originalOrder,
        username: username
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Order deleted successfully:', data);
      return data;
    } else {
      console.error('Failed to delete order');
      return null;
    }
  } catch (error) {
    console.error('Error deleting order:', error);
    return null;
  }
};

export const updateViewer = async (orderId, username, call) => {
  try {
    const response = await fetchWithAuth(`${call}/update-viewer`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId: orderId,
        username: username
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Order updated successfully:', data);
      return data;
    } else {
      console.error('Failed to update order');
      return null;
    }
  } catch (error) {
    console.error('Error updating order:', error);
    return null;
  }
};

export const submitOrderFeedback = async (call, feedbackData, selectedOrder, username) => {
  try {
    const response = await fetchWithAuth(`${call}/update-feedback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderdata: selectedOrder,
        orderId: feedbackData.orderId,
        rating: feedbackData.rating,
        comments: feedbackData.comments,
        username: username
      }),
    })
  if (response.ok) {
    const data = await response.json();
    console.log('Order feedback updated successfully:', data);
    return data;
  } else {
    console.error('Failed to update order feedback');
    return null;
  }
} catch (error) {
  console.error('Error updating order feedback:', error);
  return null;
}

};

export const submitOrderReturn = async (call, returnData, selectedOrder, username) => {
  try {
    const response = await fetchWithAuth(`${call}/update-return`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderdata: selectedOrder,
        reason: returnData.reason,
        returnMethod: returnData.returnMethod,
        additionalDetails: returnData.additionalDetails,
        urgency: returnData.urgency,
        username: username
      }),
    })
  if (response.ok) {
    const data = await response.json();
    console.log('Order feedback updated successfully:', data);
    return data;
  } else {
    console.error('Failed to update order feedback');
    return null;
  }
} catch (error) {
  console.error('Error updating order feedback:', error);
  return null;
}

};

export const fetchDisputeOrderDetails = async (call, oldOrderId, setDisputeOrderDetails) => {
  try {
    const response = await fetchWithAuth(`${call}/dispute-order-details`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ oldOrderId }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Fetched new order details successfully:', data);
      setDisputeOrderDetails(data);
    } else {
      console.error('Failed to fetch new order details');
      return null;
    }
  } catch (error) {
    console.error('Error fetching new order details:', error);
    return null;
  }
};
