import React from "react";
import Modal from "react-modal";
import ModalHeader from "../../components/ModalHeader";

const RecommendationWarningModal = ({ 
  isOpen, 
  setIsOpen, 
  warningDetails, 
  onContinue, 
  onCancel,
}) => {
    const handleClose = () => {
        if (onCancel) onCancel();
      };
      
    const handleContinue = () => {
    if (onContinue) onContinue();
    };
    
  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          width: "40%",
          height: "auto",
          maxHeight: "80%",
        },
      }}
    >
      <ModalHeader title="Recommendation Warning" />
      <div style={{ margin: "20px" }}>
        <h3 style={{ marginTop: "10px" }}>The following orders may conflict:</h3>
        <ul style={{ paddingLeft: "20px", marginTop: "10px", lineHeight: "1.5em" }}>
          {warningDetails.map((detail, index) => (
            <li key={index} style={{ marginBottom: "5px" }}>
              {detail}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "20px" }}>
        <button
          className="decline-button"
          onClick={handleClose}
          style={{ padding: "10px 20px" }}
        >
          Cancel
        </button>
        <button
          className="action-button"
          onClick={handleContinue}
          style={{ padding: "10px 20px" }}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default RecommendationWarningModal;
