import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTimes, faPaperPlane, faAngleDoubleDown} from '@fortawesome/free-solid-svg-icons';
import { formatDateTimeText, formatDateToUTCString } from '../../utils/time_zones/TimeZones';
import { fetchFiles, fetchFile } from '../../utils/invoices/invoiceUtils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchUsername } from '../../utils/users/userUtils';
import { fetchTicketInfo, fetchTicketsInfo, updateBundle } from '../../utils/locations/locationsUtils';
import { updateOrdersCode } from '../../utils/procurement/procurementUtils';
import { fetchEmailConversation } from '../../utils/notifications/notificationsUtils';

import { fetchWithAuth } from '../../utils/authentication/authenticationUtils';

import loadingSpinner from '../../assets/images/loading.gif';

import FreeProductModal from './FreeProductModal';
import BundleEditModal from './BundleEditModal';
import OrderEditModal from './OrderEditModal';
import BundleTrackingTableModal from './BundleTrackingTableModal';
import BundleTrackingTablePreview from './BundleTrackingTablePreview'
import EmailPreview from './EmailPreview'
import OperationFlow from './OperationFlow'

// Make sure to bind the modal to your appElement
Modal.setAppElement('#root');

const OperationsModal = ({ isOpen, onClose, ticketInfo, setTicketInfo, setTickets, locationName }) => {
    const [bundleOpen, setBundleOpen] = useState(true);
    const [isEditModalOpenFreeProduct, setIsEditModalOpenFreeProduct] = useState(false);
    const [isEditModalOpenBundle, setIsEditModalOpenBundle] = useState(false);
    const [isBundleTrackingModalOpen, setIsBundleTrackingModalOpen] = useState(false);
    const [invoiceExist, setInvoiceExist] = useState(false);
    let [editedOrder, setEditedOrder] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [username, setUsername] = useState([]);
    const [initialStatus, setInitialStatus] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [bundleComment, setBundleComment] = useState(ticketInfo.bundle_comment);
    const [emailConversation, setEmailConversation] = useState(null);

    const updateOperationModalInfo = () => {
        setBundleComment(ticketInfo.bundle_comment);
    };

    const handleEditOrder = (order) => {
        setInitialStatus(order.status_cat);
        setEditedOrder(order);
        setIsEditModalOpen(true);
    };

    // Function to handle order checkbox change
    const handleOrderCheckboxChange = (order) => {
        if (selectedOrders.includes(order)) {
            setSelectedOrders(selectedOrders.filter(o => o !== order));
        } else {
            setSelectedOrders([...selectedOrders, order]);
        }
    };

    const getBundleDate = (orders) => {
        // Extract order_date values from all objects in the bundle
        const allOrderDates = orders.map(order => order.order_date);
        
        // Find the maximum date from the array of order dates
        const maxDate = new Date(Math.max(...allOrderDates.map(date => new Date(date))));
  
        return maxDate;
    };

    // Function to handle Apply button click
    const handleApplyClick = async () => {

        let orderCodeToSend;

        if (selectedOrders.length === ticketInfo.orders.length){
            alert("You cannot select all the orders.");
            return;
        }

        if (selectedOrders.some(order => order.location_name !== selectedOrders[0].location_name)){
            alert("You cannot select orders from different locations.");
            return;
        }

        if (selectedOrders.length !== 0) {
            try {
                // Fetch the next order code from the API
                const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/get_max_order_code');
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const data = await response.json();
                
                // Use the returned next order code
                orderCodeToSend = data.next_order_code;
        
            } catch (error) {
                console.error('Error fetching max order code:', error);
            
                // If there's an error, fallback to order code 1
                orderCodeToSend = 1;
            }
    
            await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/create-bundle', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                orderCode: orderCodeToSend, // Example order code
                shipping: 0, // Example shipping
                taxes: 0, // Example taxes
                discount: 0,
                bundleDate: formatDateToUTCString(getBundleDate(selectedOrders)).split('T')[0].split(' ')[0],
                dueDate: formatDateToUTCString(getBundleDate(selectedOrders)).split('T')[0].split(' ')[0] // Example due date
            })
            })
            .then(response => {
            if (response.ok) {
                console.log('Bundle updated successfully');
            } else {
                console.error('Failed to update bundle');
            }
            })
            .catch(error => {
            console.error('Error updating bundle:', error);
            });
    
            await updateOrdersCode(orderCodeToSend, selectedOrders);

            await fetchTicketInfo(setTicketInfo, ticketInfo.bundle_id);

            await fetchTicketsInfo(setTickets, locationName);

        }
    
        // Clear selected orders and order code
        setSelectedOrders([]);
    
    };

    const toggleBundle = () => {
        setBundleOpen(!bundleOpen);
        if (!bundleOpen){
            setSelectedOrders([]);
        }
    };

    const checkFileExists = async (fileName) => {

        let bundlesInvoices = await fetchFiles();

        return setInvoiceExist(bundlesInvoices.includes(fileName));
    };

    const GetStatusColor = (status) => {
        switch (status) {
            case 'Placed':
                return '#71B1D9';
            case 'Confirmed':
                return 'yellow';
            case 'Backorder':
                return 'brown';
            case 'In Review':
                return 'orange';
            case 'Open':
                return '#F49C7D';
            case 'Shipped':
                return 'green';
            case 'Delivered':
                return '#006400';
            case 'Recommend':
                return 'grey';
            case 'Remove':
                return 'purple';
            case 'Moved':
                return 'blue';
            case 'Declined':
                return 'black';
            default:
                return 'grey';
        }
    };

    

    useEffect(() => {
        
        fetchUsername(setUsername);
        updateOperationModalInfo();
        fetchEmailConversation(ticketInfo.email_id, process.env.REACT_APP_PROCUREMENT_EMAIL, setEmailConversation);
        checkFileExists("Bundles-invoices/bundle_id_" + ticketInfo.bundle_id);
    
    }, [ticketInfo]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => { setBundleOpen(false); onClose(); setBundleComment(''); }}
            className="modal-content"
            overlayClassName="modal-overlay"
            contentLabel="Operations Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                },
                content: {
                    width: '95%',
                    height: '95%',
                    padding: '10px'
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflowY: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px', borderBottom: '2px solid black' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div style={{ backgroundColor: GetStatusColor(ticketInfo.bundle_status), borderRadius: '10px', padding: '10px', color: 'white' }}>{ticketInfo.bundle_status}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>{ticketInfo.location_name}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>{ticketInfo.bundle_urgency}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>{ticketInfo.bundle_manufacturer_name}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>ID {ticketInfo.bundle_id}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>Origin: {ticketInfo.is_origin_recommendation}</div>
                        <div style={{ backgroundColor: '#F49C7D', borderRadius: '10px', padding: '10px', color: 'white' }}>Order Mode: {ticketInfo.order_option}</div>
                    </div>
                    <button className="button-style" style={{cursor: 'pointer'}} onClick={() => { setBundleOpen(false); onClose(); setBundleComment('');  }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                {/* Second row */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderBottom: '2px solid black' }}>
                    <button className="button-style" style={{cursor: 'pointer'}} onClick={handleApplyClick}>Break</button>
                    <div style={{ display: 'flex', gap: '40px' }}>
                        <div>
                            <strong>Bundle Date:</strong><br/> {formatDateTimeText(ticketInfo.date)}
                        </div>
                        <div>
                            <strong>Total Price:</strong><br/> ${ticketInfo.total_price.toFixed(2)}
                        </div>
                        <div>
                            <strong>Shipping:</strong><br/> ${ticketInfo.shipping.toFixed(2)}
                        </div>
                        <div>
                            <strong>Taxes:</strong><br/> ${ticketInfo.taxes.toFixed(2)}
                        </div>
                        <div>
                            <strong>Discount:</strong><br/> ${ticketInfo.discount !== null ? (parseFloat(ticketInfo.discount).toFixed(2)) : (0)}
                        </div>
                        <div>
                            <strong>Final Total:</strong><br/> ${parseFloat(ticketInfo.total_price + ticketInfo.shipping + ticketInfo.taxes - ticketInfo.discount).toFixed(2)}
                        </div>

                        {ticketInfo.bundle_id > 0 ? (
                            <>
                                <button 
                                    className="button-style"
                                    onClick={() => {
                                        setIsEditModalOpenFreeProduct(true);
                                    }}
                                    style={{
                                        cursor: ticketInfo.bundle_status === 'Delivered' ? 'none' : 'pointer',
                                        opacity: ticketInfo.bundle_status === 'Delivered' ? 0.5 : 1
                                    }}
                                    disabled={ticketInfo.bundle_status === 'Delivered'}
                                >
                                    Add Free Product
                                </button>

                                <button 
                                    className="button-style"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setIsEditModalOpenBundle(true);
                                    }}
                                >
                                    Edit
                                </button>
                            </>
                        ) : (
                            <>
                            <button 
                                className="button-style" 
                                style={{ opacity: 0.5 }}
                                disabled
                            >
                                Add Free Product
                            </button>
                            
                            <button 
                                className="button-style" 
                                style={{ opacity: 0.5 }}
                                disabled
                            >
                                Edit
                            </button>
                            </>
                        )}

                        {invoiceExist ? (
                            <button 
                                className="button-style"
                                style={{cursor: 'pointer'}}
                                onClick={() => fetchFile("bundle_id_" + ticketInfo.bundle_id)}
                            >
                                Download
                            </button>
                        ) : (
                            <button 
                                className="button-style" 
                                style={{ opacity: 0.5 }}
                                disabled
                            >
                                Download
                            </button>
                        )}
                    </div>
                </div>

                {/* Third row */}
                <div style={{ padding: '10px', borderBottom: '2px solid black' }}>
                    <div onClick={toggleBundle} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <span>{bundleOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</span>
                        <span style={{ marginLeft: '10px' }}>Orders</span>
                    </div>
                    {bundleOpen &&
                        <div style={{ border: '1px solid black', marginTop: '10px' }}>
                            <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
                                <thead>
                                    <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                        <th>Select</th>
                                        <th>Location Name</th>
                                        <th>Order Date</th>
                                        <th>Order Urgency</th>
                                        <th>Product Name</th>
                                        <th>Product Description</th>
                                        <th>Manufacturer</th>
                                        <th>Unit Price($)</th>
                                        <th>Order Quantity</th>
                                        <th>Order Price($)</th>
                                        <th>Internal Description</th>
                                        <th>Client Comment</th>
                                        <th>Order Comment</th>
                                        <th>Order Status</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketInfo.orders.map((order, orderIndex) => (
                                        <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                            <td>
                                                {(ticketInfo.bundle_status !== 'Delivered' && ticketInfo.bundle_status !== 'Shipped') ? (
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOrders.includes(order)}
                                                        onChange={() => handleOrderCheckboxChange(order)}
                                                        style={{ width: '20px', height: '20px', alignItems: 'center' }}
                                                    />
                                                )
                                                    :
                                                    (
                                                        <span></span>
                                                    )
                                                }
                                            </td>
                                            <td>{order.location_name}</td>
                                            <td>{formatDateTimeText(order.order_date)}</td>
                                            <td>{order.order_urgency}</td>
                                            <td>{order.product_name}</td>
                                            <td>{order.product_description}</td>
                                            <td>{order.manufacturer_name}</td>
                                            <td>{order.unit_price.toFixed(2)}</td>
                                            <td>{order.order_quantity.toFixed(2)}</td>
                                            <td>{parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                            <td>{order.internal_description}</td>
                                            <td>{order.client_comment}</td>
                                            <td>{order.order_comment}</td>
                                            <td>{order.status_cat}</td>
                                            <td>
                                                <button
                                                    className="button-style"
                                                    style={{
                                                        cursor: (order.status_cat === 'Moved') ? 'none' : 'pointer',
                                                        pointerEvents: (order.status_cat === 'Moved') ? 'none' : 'auto',
                                                        opacity: (order.status_cat === 'Moved') ? 0.5 : 1
                                                    }}
                                                    onClick={() => {
                                                        handleEditOrder(order);
                                                    }}
                                                    disabled={order.status_cat === 'Moved'}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                {/* Fourth row (left side) */}
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '30%', padding: '10px', borderRight: '2px solid black' }}>
                        
                        <div style={{borderBottom: '2px solid black'}}>
                            <BundleTrackingTablePreview bundleID={ticketInfo.bundle_id} ticketInfo={ticketInfo}/>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px'}}>
                                <textarea
                                    value={bundleComment}
                                    onChange={(e) => setBundleComment(e.target.value)}
                                    style={{ width: '100%', border: 'none', outline: 'none', resize: 'none', marginRight: '10px' }}
                                />
                                <button 
                                    className="button-style"
                                    onClick={async () => {
                                        try {
                                            await updateBundle(ticketInfo.bundle_date, ticketInfo.bundle_id, ticketInfo.shipping, ticketInfo.taxes, ticketInfo.discount, bundleComment, ticketInfo.due_date, ticketInfo.eta);
                                            toast.success("Comment updated.");
                                        } catch (error) {
                                            toast.error("Error while updating comment.");
                                        }
                                    }}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                                <button 
                                    className="button-style"
                                    onClick={() => { setIsBundleTrackingModalOpen(true); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                </button>
                            </div>
                        </div>
                        
                        {!emailConversation ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',  // Center horizontally
                                    alignItems: 'center',      // Center vertically
                                    flexDirection: 'column',   // Stack items vertically
                                    height: '70%'
                                }}
                            >
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginBottom: '10px'  // Space below the spinner if needed
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                {emailConversation.error ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',  // Center horizontally
                                            alignItems: 'center',      // Center vertically
                                            flexDirection: 'column',   // Stack items vertically
                                            height: '70%'
                                        }}
                                    >
                                        No email found.
                                    </div>
                                ) : (
                                    <EmailPreview
                                        emailConversation={emailConversation}
                                        setEmailConversation={setEmailConversation}
                                        emailID={ticketInfo.email_id}
                                        bundleID={ticketInfo.bundle_id}
                                        setTickets={setTickets}
                                        locationNameSelected={locationName}
                                    />
                                )}
                            </>
                        )}

                    </div>

                    {/* Right side box for instructions */}
                    <div style={{ flex: 1, padding: '10px' }}>
                        <OperationFlow 
                            status={ticketInfo.bundle_status} 
                            locationName={ticketInfo.location_name} 
                            ticketInfo={ticketInfo} 
                            setTicketInfo={setTicketInfo} 
                            username={username} 
                            setTickets={setTickets}
                            onClose={onClose}
                            emailConversation={emailConversation}
                            setEmailConversation={setEmailConversation}
                            emailID={ticketInfo.email_id}
                            locationNameSelected={locationName}
                        />
                    </div>
                </div>
            </div>
            <FreeProductModal isOpen={isEditModalOpenFreeProduct} onClose={() => setIsEditModalOpenFreeProduct(false)} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} />
            {ticketInfo && <BundleEditModal isOpen={isEditModalOpenBundle} onClose={() => setIsEditModalOpenBundle(false)} editedBundle={ticketInfo} setTicketInfo={setTicketInfo} invoiceExist={invoiceExist} setInvoiceExist={setInvoiceExist} username={username} locationNameSelected={locationName} setTickets={setTickets}/>}
            {editedOrder && <OrderEditModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} editedOrder={editedOrder} setEditedOrder={setEditedOrder} orders={ticketInfo.orders} setTicketInfo={setTicketInfo} username={username} initialStatus={initialStatus} locationNameSelected={locationName} setTickets={setTickets}/>}
            {ticketInfo && <BundleTrackingTableModal isOpen={isBundleTrackingModalOpen} onClose={() => setIsBundleTrackingModalOpen(false)} bundleID={ticketInfo.bundle_id} />}
            <ToastContainer/>       
        </Modal>
    );
};

export default OperationsModal;
