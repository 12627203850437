import { fetchWithAuth } from '../authentication/authenticationUtils';

export const fetchProductUsage = async (locationName, productId, setProductUsageData) => {
    const data = {
        location_name: locationName,
        product_id: productId
    };

    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/product-usage-per-week`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const usageData = await response.json();
        setProductUsageData(usageData);
        
        return usageData
    } catch (error) {
        console.error('Failed to fetch product usage data:', error);
        return null
    }
}
