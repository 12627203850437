import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ContentEditable from 'react-contenteditable';
import InvoiceBundleModal from './InvoiceBundleModal';
import { fetchSuppliersData, fetchTicketsInfo } from '../../utils/locations/locationsUtils';
import { newDate } from '../../utils/time_zones/TimeZones';
import { handleBundleSubmit } from '../../utils/procurement/procurementUtils';

import { fetchWithAuth } from '../../utils/authentication/authenticationUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import loadingSpinner from '../../assets/images/loading.gif';

const BundleEditModal = ({isOpen, onClose, editedBundle, setTicketInfo, invoiceExist, setInvoiceExist, username, locationNameSelected, setTickets}) => {

    const [emailData, setEmailData] = useState(null);
    const [dueDateOption, setDueDateOption] = useState('immediate');
    let [dueDate, setDueDate] = useState('');
    let [editedBundleStatus, setEditedBundleStatus] = useState(editedBundle.bundle_status);
    const [optionOrder, setOptionOrder] = useState('platform');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [selectedSupplierId, setSelectedSupplierId] = useState(editedBundle.bundle_supplier_id); 
    const [bundleComment, setBundleComment] = useState(editedBundle.bundle_comment);
    const [editedShippingPrice, setEditedShippingPrice] = useState(editedBundle.shipping);
    const [editedTaxesPrice, setEditedTaxesPrice] = useState(editedBundle.taxes);
    const [editedDiscount, setEditedDiscount] = useState(editedBundle.discount);
    const [suppliersData, setSuppliersData] = useState(null); 
    const [isBundleInvoiceModalOpen, setBundleInvoiceModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateBundleInfo = async () => {
        setDueDate(editedBundle.due_date);
        setEditedBundleStatus(editedBundle.bundle_status);
        setOptionOrder(editedBundle.order_option);
        setTrackingNumber(editedBundle.tracking_number);
        setSelectedSupplierId(editedBundle.bundle_supplier_id);
        setBundleComment(editedBundle.bundle_comment);
        setEditedShippingPrice(editedBundle.shipping);
        setEditedTaxesPrice(editedBundle.taxes);
        setEditedDiscount(editedBundle.discount);
    };


    // Gets unique category of bundles
    const bundleStatusCategories = [
        'Placed',
        'Pending',
        'In Review',
        'Open',
        'Shipped',
        'Delivered',
        'Recommend',
        'Remove',
        'Declined',
        'Confirmed',
        'Backorder',
        'In Dispute',
        'Returned'
    ];

    const getTableOrder = (orders) => {
        return `
            <div style="border: 2px solid #87D3D7;
                width: 400px;
                box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);"
            >
              <table style="
                      width: 400px;
                      border-collapse: separate;
                      border-spacing: 0;
                      position: relative;"
              >
                <thead style="
                            top: 0;
                            z-index: 1;"
                >
                  <tr style="font-size: 13px; background-color: white;">
                      <th style="border-bottom: 2px solid black;
                                padding: 10px;
                                text-align: left;">Product Name</th>
                      <th style="border-bottom: 2px solid black;
                                padding: 10px;
                                text-align: left;">Product Description</th>
                      <th style="border-bottom: 2px solid black;
                                padding: 10px;
                                text-align: left;">Order Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  ${orders.map((product, index) => `
                      <tr key='${index}' style="font-size: 13px;
                                                background-color: ${index % 2 === 0 ? '#ebf4f5' : '#ffffff'};">
                          <td style="padding: 10px; text-align: left;">${product.product_name}</td>
                          <td style="padding: 10px;
                                    text-align: left;">${product.product_description}</td>
                          <td style="padding: 10px;
                                    text-align: left;">${product.order_quantity}</td>
                      </tr>
                  `).join('')}
                </tbody>
              </table>
            </div>
        `;
    };

    const handleBundleLoadMail = async (bundle) => {
        try {
            const orders = bundle.orders;
      
            // Check if all orders have the same supplier_id
            const uniqueSupplierIds = [...new Set(orders.map(order => order.supplier_id))];
      
            let resp_contact_email = '';
            let resp_contact_name = '';
            let supplierName = '';
      
            if (uniqueSupplierIds.length !== 1) {
                console.log('Orders in the bundle have different supplier IDs. Skipping email preparation.');    
            }
            else{
              const supplierId = uniqueSupplierIds[0];
      
              // Fetch the supplier data using the supplier_id
              const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + `/suppliers?supplier_id=${supplierId}`);
              if (!response.ok) {
                  throw new Error('Error fetching supplier data');
              }
              const supplierData = await response.json();
        
              // Extract receiver_email and supplier_contact from supplierData
              const { supplier_name, contact_email, contact_name } = supplierData[0];
      
              resp_contact_email = contact_email;
              resp_contact_name = contact_name;
              supplierName = supplier_name;
      
            }
      
            // Prepare data to send to the /send_order_email API
            setEmailData({
              recipient_emails: [resp_contact_email],
              client_name: orders[0].location_name,
              contact_name: resp_contact_name,
              subject: `${orders[0].location_name} <> ${supplierName} Order`,
              cc_emails: [''],
              message: `Hi ${resp_contact_name},
                <br/>
                <br/>
                ${orders[0].location_name} would like to place the following order:
                <br/>
                <br/>
                ${getTableOrder(orders)}
                <br/>
                <br/>
                Please let us know if any adjustments to the order could favorably impact the pricing and once the order is confirmed. It would also be great if you could share the invoice and tracking number once available.
                <br/>
                <br/>
                Thank you very much and best regards,
                <br/>
                <br/>
                Medvelle Team`,
                bundle_id: bundle.bundle_id
              });
          
        } catch (error) {
            // Handle errors appropriately
            console.error('Error processing order status change:', error);
        }
    };


    // Function to format the date string to "yyyy-MM-dd" format
    const getFormattedDate = (dateString) => {
        if (!dateString) return ''; // Handle cases where dateString is null or undefined
        
        try{
            const dateObject = newDate(dateString);
            const year = dateObject.getFullYear();
            const month = `${dateObject.getMonth() + 1}`.padStart(2, '0'); // Adding 1 to month because JavaScript months are zero-based
            const day = `${dateObject.getDate()}`.padStart(2, '0');
            
            return `${year}-${month}-${day}`;

        }catch(error){
            console.log(`Error formatting date: ${error}.`);
            return '2021-01-01'
        }
    };

    const handleBundleStatusChange = (e) => {
        const status = e.target.value;
    
        setEditedBundleStatus(status);
    
        if (status === 'Shipped' || status === 'Delivered'){
          setDueDateOption('');
        }

    };

    const preProcessing = async () => {
        await handleBundleLoadMail(editedBundle);

        if (editedBundle.bundle_date == editedBundle.due_date){
            setDueDateOption('immediate');
        }
        else{
            setDueDateOption('later');
        }

        setDueDate(getFormattedDate(editedBundle.due_date));

        setTrackingNumber(editedBundle.tracking_number);
    };

    const parseEmails = (input) => {
        // Split the string by commas or comma followed by spaces
        const emailArray = input.split(/,\s*/);
        // Trim whitespace from each email
        return emailArray.map(email => email.trim());
    };

    useEffect(() => {

        // Pre-processing
        preProcessing();

        // Get suppliers data
        fetchSuppliersData(setSuppliersData);

        updateBundleInfo();
    
    }, [editedBundle, isOpen]);

    const handleFormSubmit = async () => {
        await handleBundleSubmit(
            editedBundle, editedBundleStatus, dueDateOption, setTicketInfo, onClose, 
            setIsLoading, selectedSupplierId, optionOrder, emailData, trackingNumber, invoiceExist,
            editedShippingPrice, editedTaxesPrice, editedDiscount, bundleComment, dueDate, username, editedBundle.eta
        );

        await fetchTicketsInfo(setTickets, locationNameSelected);
    };

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={() => {
                onClose(); 
                updateBundleInfo();
            }}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                },
                content: {
                    width: '30%',
                    height: '90%'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button 
                    className="button-style"
                    onClick={() => { onClose(); updateBundleInfo(); }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <h3>Edit Bundle</h3>
            {editedBundle && (
                <div style={{ maxHeight: '85%', maxWidth: '100%', overflowY: 'auto' }}>
                    <form>
                        {(editedBundle.bundle_status === 'Shipped' || editedBundle.bundle_status === 'Delivered') ? (
                            <div className="input-group">
                                <label htmlFor="bundleStatus">Status:</label>
                                <select
                                    id="bundleStatus"
                                    value={editedBundleStatus}
                                    onChange={handleBundleStatusChange}
                                    disabled={editedBundle.bundle_status === 'Delivered'}
                                >
                                    <option value="Shipped">Shipped</option>
                                    <option value="Delivered">Delivered</option>
                                </select>
                                <label htmlFor="comment">Comment:</label>
                                <input
                                    type="text"
                                    id="comment"
                                    value={bundleComment}
                                    onChange={(e) => setBundleComment(e.target.value)}
                                />
                                {editedBundleStatus === 'Shipped' && (
                                    <div>
                                        <label htmlFor="trackingNumber">Tracking Number:</label>
                                        <input
                                            type="text"
                                            id="trackingNumber"
                                            value={trackingNumber}
                                            onChange={(e) => setTrackingNumber(e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="input-group">
                                    <label htmlFor="bundleStatus">Status:</label>
                                    <select
                                        id="bundleStatus"
                                        value={editedBundleStatus}
                                        onChange={handleBundleStatusChange}
                                    >
                                        {bundleStatusCategories && bundleStatusCategories.sort().map(status => (
                                            <option key={status} value={status}>
                                                {status}
                                            </option>
                                        ))}
                                    </select>
                                    {editedBundleStatus === 'Shipped' && (
                                        <div>
                                            <label htmlFor="trackingNumber">Tracking Number:</label>
                                            <input
                                                type="text"
                                                id="trackingNumber"
                                                value={trackingNumber}
                                                onChange={(e) => setTrackingNumber(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="input-group">
                                    <label htmlFor="shippingPrice">Shipping Price:</label>
                                    <input
                                        type="number"
                                        id="shippingPrice"
                                        value={editedShippingPrice}
                                        onChange={(e) => setEditedShippingPrice(e.target.value)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="taxesPrice">Taxes Price:</label>
                                    <input
                                        type="number"
                                        id="taxesPrice"
                                        value={editedTaxesPrice}
                                        onChange={(e) => setEditedTaxesPrice(e.target.value)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="discount">Discount:</label>
                                    <input
                                        type="number"
                                        id="discount"
                                        value={editedDiscount}
                                        onChange={(e) => setEditedDiscount(e.target.value)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="comment">Comment:</label>
                                    <input
                                        type="text"
                                        id="comment"
                                        value={bundleComment}
                                        onChange={(e) => setBundleComment(e.target.value)}
                                    />
                                </div>
                                {editedBundleStatus === 'Placed' && editedBundle.bundle_status !== 'Placed' && (
                                    <div className="input-group">
                                        <label htmlFor="dueDateOption">Order Option:</label>
                                        <select
                                            value={optionOrder}
                                            onChange={(e) => setOptionOrder(e.target.value)}
                                        >
                                            <option value="platform">Platform</option>
                                            <option value="email">Email</option>
                                            <option value="phone">Phone</option>
                                        </select>
                                        {optionOrder === 'email' && (
                                            <>
                                                <label htmlFor="emailAddress">Sender Email:</label>
                                                <input
                                                    type="email"
                                                    id="emailAddress"
                                                    value={emailData && emailData.sender_email}
                                                    onChange={(e) => setEmailData({ ...emailData, sender_email: e.target.value })}
                                                    style={{ width: '100%' }}
                                                />
                                                <label htmlFor="emailAddress">Recipient Email(s):</label>
                                                <input
                                                    type="email"
                                                    id="emailAddress"
                                                    value={emailData && emailData.recipient_emails}
                                                    onChange={(e) => setEmailData({ ...emailData, recipient_emails: parseEmails(e.target.value) })}
                                                    style={{ width: '100%' }}
                                                />
                                                <label htmlFor="emailAddress">CC Email(s):</label>
                                                <input
                                                    type="email"
                                                    id="emailAddress"
                                                    value={emailData && emailData.cc_emails}
                                                    onChange={(e) => setEmailData({ ...emailData, cc_emails: parseEmails(e.target.value) })}
                                                    style={{ width: '100%' }}
                                                />
                                                <label htmlFor="emailSubject">Subject Email:</label>
                                                <input
                                                    type="text"
                                                    id="emailSubject"
                                                    value={emailData && emailData.subject}
                                                    onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                                                    style={{ width: '100%' }}
                                                />
                                                <label htmlFor="emailMessage">Edit Email:</label>
                                                <ContentEditable
                                                    html={emailData ? emailData.message : ''}
                                                    onChange={(e) => { setEmailData({ ...emailData, message: e.target.value }); }}
                                                    style={{
                                                        width: '100%',
                                                        maxHeight: '120px',
                                                        padding: '5px',
                                                        overflowY: 'auto',
                                                        backgroundColor: '#fff',
                                                        marginTop: '10px',
                                                        marginBottom: '10px'
                                                    }}
                                                />
                                            </>
                                        )}
                                        <div className="input-group">
                                            <label htmlFor="supplier">Supplier:</label>
                                            <select
                                                value={selectedSupplierId}
                                                onChange={(e) => setSelectedSupplierId(e.target.value)}
                                            >
                                                <option value="0">All suppliers</option>
                                                {suppliersData && suppliersData.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name)).map(supplier => (
                                                    <option key={supplier.supplier_id} value={supplier.supplier_id}>
                                                        {supplier.supplier_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {(editedBundleStatus === 'Shipped' || editedBundleStatus === 'Delivered') && (
                                    <>
                                        <div className="input-group">
                                            <label htmlFor="dueDateOption">Due Date Option:</label>
                                            <select
                                                id="dueDateOption"
                                                value={dueDateOption}
                                                onChange={(e) => setDueDateOption(e.target.value)}
                                            >
                                                <option value="">Select Option</option>
                                                <option value="immediate">Immediate</option>
                                                <option value="later">Later</option>
                                            </select>
                                            {dueDateOption === 'later' && (
                                                <div>
                                                    <label htmlFor="dueDate">Due Date:</label>
                                                    <input
                                                        type="date"
                                                        id="dueDate"
                                                        value={dueDate}
                                                        onChange={(event) => {
                                                            const formattedDate = event.target.value.replace(/[^0-9-]/g, '');
                                                            setDueDate(formattedDate);
                                                        }}
                                                        placeholder="YYYY-MM-DD"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {!invoiceExist && (
                                            <div className="input-group">
                                                <label htmlFor="uploadInvoice">Upload Invoice:</label>
                                                <br />
                                                <a
                                                    href="#"
                                                    onClick={() => {
                                                        setBundleInvoiceModalOpen(true);
                                                    }}
                                                    style={{
                                                        fontSize: '14px',
                                                        display: 'inline-block',
                                                        marginTop: '10px',
                                                        padding: '4px 10px',
                                                        backgroundColor: '#31bac1',
                                                        color: '#fff',
                                                        border: '3px solid #31bac1',
                                                        borderRadius: '8px',
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    Upload
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        <div 
                            className="input-group"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}
                        >
                            {isLoading ? (
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginRight: '10px'
                                    }}
                                />
                            ) : (
                                <button
                                    type="button"
                                    onClick={handleFormSubmit}
                                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                >
                                    Submit
                                </button>
                            )}
                            <button
                                type="button"
                                onClick={() => {
                                    onClose(); updateBundleInfo();
                                }}
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <InvoiceBundleModal isOpen={isBundleInvoiceModalOpen} onClose={() => setBundleInvoiceModalOpen(false)} editedBundle={editedBundle} setInvoiceExist={setInvoiceExist}/>
        </Modal>
    );
}

export default BundleEditModal;
