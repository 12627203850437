import React, {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import UnitConversionDisplay from '../inventory/UnitConversionDisplay';
import ModalHeader from '../../components/ModalHeader';
import { updateOrderDetails } from '../../utils/orders/ordersUtils';
import {toast} from 'react-toastify';

const OrderEditModal = ({isOpen, setIsOpen, onSubmit=()=>{}, order, username}) => {
    const [orderQuantity, setOrderQuantity] = useState(0);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [orderComment, setOrderComment] = useState('');
    const [orderUrgency, setOrderUrgency] = useState('5+ business days');
    const [title, setTitle] = useState('');

    useEffect(() => {
        setOrderQuantity(order.order_quantity);
        setOrderUrgency(order?.order_urgency ? order?.order_urgency : '5+ business days');
        setTitle(`${ order?.product_name } ${ order?.product_description ? `- ${ order?.product_description }` : '' }`);
        setOrderComment(order?.client_comment ? order?.client_comment : '');
    }, [isOpen]);

    const closeModal = () => {
        setOrderQuantity(0);
        setOrderUrgency('5+ business days');
        setIsOpen(false);
        setOrderComment('');
    };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    return (<Modal
        overlayClassName="white-modal-overlay"
        className="white-modal"
        isOpen={isOpen}
        onRequestClose={closeModal}
    >
        <ModalHeader description={'Edit order recommendation details for'} title={title} showExit={true} onClose={closeModal}/>

        {order && (
            <div style={{marginLeft: '20px', marginRight: '20px'}}>
                <div style={{marginBottom: '10px', width: '150px'}}>
                    <h3 style={{marginTop: '10px', marginBottom: '10px'}}>Quantity</h3>
                </div>
                <UnitConversionDisplay
                    quantity={orderQuantity}
                    setQuantity={setOrderQuantity}
                    quantityConversion={order.quantity_conversion}
                    units={order.product_unit}
                />

                <div style={{marginBottom: '10px', }}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <h3 style={{margin: 0, marginTop: '10px'}}>Preferred Delivery Timeline</h3>
                        <button className="info-button" onClick={togglePopup}>&#x2139;</button>
                    </div>
                    <select className='select-box' style={{marginTop: '8px', marginBottom: '8px', width: '150px'}}
                        value={orderUrgency} onChange={(e) => setOrderUrgency(e.target.value)}>
                        <option value="1 business day">1 business day</option>
                        <option value="2-3 business days">2-3 business days</option>
                        <option value="3-5 business days">3-5 business days</option>
                        <option value="5+ business days">5+ business days</option>
                    </select>
                </div>

                {orderUrgency === '1 business day' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Medvelle will process this order ASAP, additional delivery charges will apply.
                    </p>
                )}

                {orderUrgency === '2-3 business days' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Additional delivery charges may apply depending on the supplier and your location.
                    </p>
                )}

                {(orderUrgency === '3-5 business days' || orderUrgency === '5+ business days') && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Thank you for being proactive with orders, you just saved on additional delivery charges!
                    </p>
                )}

                {isPopupVisible && (
                    <div className="popup">
                        <div className="popup-inner">
                            <p>Please indicate your preferred delivery timeline - Medvelle will make every effort to meet it.</p>
                            <p>The official order cutoff for next business day orders is 2PM ET. Please contact <a href={`mailto:${process.env.REACT_APP_PROCUREMENT_EMAIL}`} style={{textDecoration: 'none', color: '#D46B45'}}><b>{process.env.REACT_APP_PROCUREMENT_EMAIL}</b></a> for any urgent matters.</p>
                            <p>For maximum savings and minimized shipping costs, Medvelle encourages placing orders ahead of time.</p>

                            <button style={{backgroundColor: '#31bac1', color: 'black', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}} onClick={togglePopup}>Close</button>
                        </div>
                    </div>
                )}

                <div >
                    <h3>Order Comment</h3>
                    <textarea
                        style={{width: '50%', marginRight: '20px', maxWidth: '95%'}}
                        className='select-box'
                        value={orderComment}
                        maxLength="1500" // Limiting to 20 characters
                        onChange={(e) => setOrderComment(e.target.value)}
                    />
                </div>

                {(
                    <button className='action-button'
                        onClick={async () => {
                            if (!orderQuantity) {
                                toast.error('Please enter a valid order quantity.')
                                return
                            }
                            const success = await updateOrderDetails(order, {order_quantity: orderQuantity, order_urgency: orderUrgency, client_comment: orderComment}, username, process.env.REACT_APP_BACKEND_URL)
                            if (success) {
                                toast.success('Successfully edited the order.')
                            }
                            onSubmit();
                            closeModal();
                        }
                        }
                        style={{marginTop: '20px', marginBottom: '20px'}}>
                        Submit Edit
                    </button>
                )}

            </div>
        )}
    </Modal>);
};

export default OrderEditModal;