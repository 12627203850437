import { fetchWithAuth } from '../authentication/authenticationUtils';

export const fetchFlagsToResolve = async ({locationName, setFlagsToResolve, setIsLoading, signal = undefined}) => {
    try {
        setIsLoading(true);
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get_flags_to_resolve`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({locationName}),
            ...(signal ? {signal} : {}),
        });
        if (!response.ok) {
            throw new Error('Failed to fetch flags to resolve');
        }
        const data = await response.json();
        setFlagsToResolve(data);

        setIsLoading(false);

        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching flags to resolve:', error);
            setIsLoading(false);
        }

        return null;
    }
};

export const fetchFlagDetails = async (productId, locationName, setFlagDetails) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/flag-details`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({product_id: productId, location_name: locationName})
        });

        if (!response.ok) {
            throw new Error(`Error: ${ response.status } ${ response.statusText }`);
        }

        const data = await response.json();

        setFlagDetails(data);

        return data;
    } catch (error) {
        console.error('Failed to fetch flag details:', error);
        return null;
    }
};

export const getDeltaStatus = ({flagToResolve, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber}) => {
    const delta = getUpdatedDeltaQuantity({flagToResolve, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber});

    if (delta > 0) {
        return {
            textDeltaStatus: `${ delta } ${ flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)` } gained`,
            colorDeltaStatus: '#e9453b',
        };
    } else if (delta < 0) {
        return {
            textDeltaStatus: `${ Math.abs(delta) } ${ flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)` } lost`,
            colorDeltaStatus: '#e9453b',
        };
    } else {
        return {
            textDeltaStatus: 'Resolved',
            colorDeltaStatus: '#119b1a',
        };
    }
};

export const getUpdatedDeltaQuantity = ({flagToResolve, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber}) => {
    const delta = isNaN(deltaBeforeProcess) ? flagToResolve.total_delta : parseFloat(deltaBeforeProcess);
    const orderDeliveredCheck = isNaN(orderDeliveredCheckNumber) ? 0 : parseFloat(orderDeliveredCheckNumber);
    const moveProduct = isNaN(moveProductNumber) ? 0 : parseFloat(moveProductNumber);
    const freeProduct = isNaN(freeProductNumber) ? 0 : parseFloat(freeProductNumber);
    const brokenExpired = isNaN(brokenExpiredNumber) ? 0 : parseFloat(brokenExpiredNumber);
    const gaveProduct = isNaN(gaveProductNumber) ? 0 : parseFloat(gaveProductNumber);
    const externalOrder = isNaN(externalOrderNumber) ? 0 : parseFloat(externalOrderNumber);
    let delta_final = parseFloat(parseFloat(delta + orderDeliveredCheck + moveProduct - freeProduct + brokenExpired + gaveProduct - externalOrder).toFixed(1));

    if (checkDeltaInterval(delta_final, flagToResolve)) {
        delta_final = 0;
    }

    return delta_final;
};

export const checkDeltaInterval = (delta, flagToResolve) => {

    const delta_inventory_quantity = delta * flagToResolve.quantity_conversion;

    const bound = flagToResolve.quantity_conversion * funcFactorQuantityConversion(flagToResolve.quantity_conversion);

    return (-bound <= delta_inventory_quantity && delta_inventory_quantity <= bound);
};

function funcFactorQuantityConversion(conversionQuantity) {
    let factor = 1 / Math.sqrt(4 * conversionQuantity);

    if (factor > 0.5) {
        factor = 0.5;
    } else if (factor < 0.2) {
        factor = 0.2;
    }

    return factor;
}

export const calculateFilteredDeltaSumExplained = (flagDetails) => {
    return flagDetails
        .filter(detail => !detail.origin.startsWith('Spotcheck') && !detail.origin.startsWith('Edit Inventory'))
        .reduce((accumulator, current) => accumulator + parseFloat(current.delta), 0).toFixed(1);
};

export const calculateFilteredDeltaSumUnexplained = (flagDetails) => {
    return flagDetails
        .filter(detail => detail.origin.endsWith('Flag'))
        .reduce((accumulator, current) => accumulator + parseFloat(current.delta), 0).toFixed(1);
};