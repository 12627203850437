import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { replyToEmail } from '../../utils/notifications/notificationsUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import EmailTrackingModal from './EmailTrackingModal';
import { toast } from 'react-toastify';
import ContentEditable from 'react-contenteditable';
import { fetchTicketsInfo, fetchSuppliers } from '../../utils/locations/locationsUtils';
import { handleBundleSubmit } from '../../utils/procurement/procurementUtils';


const PlacedFlow = ({ ticketInfo, setTicketInfo, onClose, origin, emailConversation, setEmailConversation, emailID, locationNameSelected, setTickets, username }) => {
    
    const { body, from, sent_date, subject, message_id, to_recipients, cc_recipients } = emailConversation.lastMessage;
    const [replyMessage, setReplyMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState(process.env.REACT_APP_PROCUREMENT_EMAIL);
    const [recipientEmails, setRecipients] = useState(from === senderEmail ? to_recipients :[from]);
    const [ccEmails, setCCEmails] = useState(cc_recipients);
    const [isSendReplyLoading, setIsSendReplyLoading] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [templateType, setTemplateType] = useState('Personalized'); // New state for template type
    const [supplier, setSupplier] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch suppliers
        const loadSuppliers = async () => {
            const supplier_data = await fetchSuppliers(ticketInfo.location_name, ticketInfo.bundle_supplier_id);
            
            setSupplier(supplier_data[0]);

        };

        loadSuppliers();
    }, [ticketInfo]);

    // Function to update the reply message based on selected template
    const updateReplyMessage = (template) => {
        if (template === 'Personalized') {
            setReplyMessage(`
                Dear [Name],<br><br>
                Thank you for reaching out.<br><br>
                Best regards,<br>
                Medvelle Team
            `);
        } else if (template === 'Thank You') {
            setReplyMessage(`
                Great, thank you!<br><br>
                Best regards,<br>
                Medvelle Team
            `);
        }
    };

    // Handle template type change
    const handleTemplateChange = (e) => {
        const selectedTemplate = e.target.value;
        setTemplateType(selectedTemplate);
        updateReplyMessage(selectedTemplate);
    };

    const handleReply = async () => {
        try {
            setIsSendReplyLoading(true);
            await replyToEmail(senderEmail, message_id, replyMessage, recipientEmails, ccEmails, ticketInfo.bundle_id);
            setIsSendReplyLoading(false);
            toast.success('Reply sent successfully!')
            onClose();
        } catch (error) {
            toast.error('Failed to send reply:', error)
            console.error('Failed to send reply:', error);
            setIsSendReplyLoading(false);
        }
        setReplyMessage('');
    };


    const handleConfirmed = async () => {
        await handleReply();

        await handleFormSubmit();

    };

    const handleFormSubmit = async () => {
        await handleBundleSubmit(
            ticketInfo, 'Confirmed', '', setTicketInfo, onClose, 
            setIsLoading, ticketInfo.bundle_supplier_id, origin,
            {}, ticketInfo.tracking_number, false,
            ticketInfo.shipping, ticketInfo.taxes, ticketInfo.discount, ticketInfo.bundle_comment, ticketInfo.due_date, username
        );

        await fetchTicketsInfo(setTickets, locationNameSelected);
    };

    const parseEmails = (input) => {
        const emailArray = input.split(/,\s*/);
        return emailArray.map(email => email.trim());
    };

    const formatPhoneNumber = (phoneNumber) => {
        try{

            if (phoneNumber === '' || !phoneNumber){
                return '';
            }

            const phoneString = phoneNumber.toString();
          
            const areaCode = phoneString.slice(0, 3);
            const centralOfficeCode = phoneString.slice(3, 6);
            const lineNumber = phoneString.slice(6, 10);
          
            return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
        } catch (error){
            return phoneNumber;
        }
        
    };

    return (
        <div className="placed-flow">
            <div>
                <p><strong>Customer Service Phone Number:</strong> {supplier && formatPhoneNumber(supplier.cust_service_phone)} | <strong>Customer Service Email:</strong> {supplier && supplier.cust_service_email}</p>
            </div>
            <div>
                <p><strong>Platform website:</strong> {supplier && supplier.website} | <strong>Username:</strong> {supplier && supplier.username} | <strong>Password:</strong> {supplier && supplier.password}</p>
            </div>
            <div>
                <p><strong>Rep Name:</strong> {supplier && (supplier.rep_first_name + ' ' + supplier.rep_last_name)} | <strong>Rep Email:</strong> {supplier && supplier.rep_email} | <strong>Rep Phone Number:</strong> {supplier && formatPhoneNumber(supplier.rep_phone)}</p>
            </div>
            {origin === 'email' && (
                <div className="email-flow">
                    <div>
                        <strong>Subject:</strong> {subject}
                        <br />
                        <strong>From:</strong> {from}
                        <br />
                        <strong>To:</strong> {to_recipients.join(', ')}
                        <br />
                        <strong>CC:</strong> {cc_recipients.join(', ')}
                        <br />
                        <strong>Last Message Date:</strong> {new Date(sent_date).toLocaleString()}
                    </div>

                    <div
                        style={{
                            border: '2px solid #87D3D7',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '90%',
                            maxHeight: '80px',
                            overflowY: 'auto',
                            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                            padding: '20px',
                            backgroundColor: 'white',
                            margin: '20px auto'
                        }}
                    >
                        <div
                            className="email-body"
                            dangerouslySetInnerHTML={{ __html: body }}
                            style={{
                                marginTop: '20px',
                                maxHeight: '100%'
                            }}
                        />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <label htmlFor="templateType">Select Template:</label>
                        <select
                            id="templateType"
                            value={templateType}
                            onChange={handleTemplateChange}
                            style={{ width: '100%', marginBottom: '10px' }}
                        >
                            <option value="Personalized">Personalized</option>
                            <option value="Thank You">Thank You</option>
                        </select>

                        <label htmlFor="senderEmail">Sender Email:</label>
                        <input
                            type="email"
                            id="senderEmail"
                            value={senderEmail}
                            onChange={(e) => setSenderEmail(parseEmails(e.target.value))}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="recipientEmails">Recipient Email(s):</label>
                        <input
                            type="email"
                            id="recipientEmails"
                            value={recipientEmails}
                            onChange={(e) => setRecipients(parseEmails(e.target.value))}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="ccEmails">CC Email(s):</label>
                        <input
                            type="email"
                            id="ccEmails"
                            value={ccEmails}
                            onChange={(e) => setCCEmails(parseEmails(e.target.value))}
                            style={{ width: '100%' }}
                        />

                        <label htmlFor="reply" style={{ marginRight: '10px' }}>Reply:</label>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <ContentEditable
                                html={replyMessage}
                                onChange={(e) => setReplyMessage(e.target.value)}
                                style={{
                                    width: '100%',
                                    height: '100px',
                                    padding: '5px',
                                    overflowY: 'auto',
                                    backgroundColor: '#fff',
                                    marginRight: '10px',
                                    marginBottom: '10px'
                                }}
                            />
                            { (isSendReplyLoading || isLoading) ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <img
                                        src={loadingSpinner}
                                        alt="loading-spinner"
                                        style={{
                                            height: '20px',
                                            marginRight: '10px',
                                            padding: '10px'
                                        }}
                                    />
                                </div>
                            ) : templateType === 'Personalized' ? (
                                <button
                                    className="button-style"
                                    onClick={async () => {await handleReply(); await fetchTicketsInfo(setTickets, locationNameSelected);}}
                                    style={{ marginRight: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            ) : (
                                <button
                                    className="button-style"
                                    onClick={handleConfirmed}
                                    style={{ marginRight: '10px', cursor: 'pointer' }}
                                >
                                    Move To Confirmed
                                </button>
                            )}
                            <button
                                className="button-style"
                                onClick={() => setIsEmailModalOpen(true)}
                                style={{ cursor: 'pointer' }}
                            >
                                <FontAwesomeIcon icon={faAngleDoubleDown} />
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {origin === 'phone' && (
                <>
                    {isLoading ? (
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginBottom: '10px'  // Space below the spinner if needed
                            }}
                        />
                    ) : (
                        <button
                            className="button-style"
                            onClick={handleFormSubmit}
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                        >
                            Move To Confirmed
                        </button>
                    )}

                </>
            )}

            <EmailTrackingModal 
                isOpen={isEmailModalOpen} 
                onClose={() => setIsEmailModalOpen(false)} 
                emailConversation={emailConversation} 
                setEmailConversation={setEmailConversation} 
                emailID={emailID} 
                bundleID={ticketInfo.bundle_id}
                setTickets={setTickets}
                locationNameSelected={locationNameSelected}
            />
        </div>
    );
};

export default PlacedFlow;
