import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from 'react-simple-maps';
import Chart from 'chart.js/auto';
import 'react-datepicker/dist/react-datepicker.css';
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import { formatDateToUTCString, newDate } from '../utils/time_zones/TimeZones';

import { fetchWithAuth } from '../utils/authentication/authenticationUtils';

import DatePicker from 'react-datepicker';

const Corporate = ({ call }) => {
    const contentRef = useRef();

    let center = [0, 0]; // Initial center coordinates
    let zoom = 1; // Initial zoom level
    const [selectedMarkers, setSelectedMarkers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('VIO Strongsville');
    const [selectedGroup, setSelectedGroup] = useState('Elevate Beauty Partners');
    const [locations, setLocations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(['VIO Strongsville']);
    const [startDateCalendar, setStartDateCalendar] = useState(newDate('2024-03-02'));
    const [endDateCalendar, setEndDateCalendar] = useState(newDate('2024-04-02'));
    const [topManufacturers, setTopManufacturers] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const [topServices, setTopServices] = useState([]);
    const [topLocations, setTopLocations] = useState([]);
    const [isLoadingTopProducts, setIsLoadingTopProducts] = useState(false);
    const [isLoadingTopManufacturers, setIsLoadingTopManufacturers] = useState(false);
    const [isLoadingTopServices, setIsLoadingTopServices] = useState(false);
    const [isLoadingServicesEvolution, setIsLoadingServicesEvolution] = useState(false);
    const [isLoadingTopLocations, setIsLoadingTopLocations] = useState(false);
    const [isLoadingLocationsEvolution, setIsLoadingLocationsEvolution] = useState(false);
    const [evolutionServices, setEvolutionServices] = useState([]);
    const [periodsEvolutionServices, setPeriodsEvolutionServices] = useState([]);
    const [evolutionLocations, setEvolutionLocations] = useState([]);
    const [periodsEvolutionLocations, setPeriodsEvolutionLocations] = useState([]);


    const [mode, setMode] = useState('location');

    const [mapType, setMapType] = useState('us'); // State to toggle between US and World map


    
    useEffect(() => {
            fetchLocations();
            fetchTopManufacturers(formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));
            fetchTopProducts(formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));
            fetchTopServices(formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));
            fetchEvolutionServices(formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));

            contentRef.current.style.marginLeft = '250px';
            contentRef.current.style.marginRight = '50px';
    }, [selectedLocations]);

    function groupLocationsByGroupName(locations) {
        const groups = {};
      
        locations.forEach(location => {
          const groupName = location.group_name;
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(location.location_name);
        });
      
        return groups;
    }

    const fetchEvolutionLocations = async (locationNames, startDate='2024-03-01', endDate = '2024-04-01') => {

        try {

            setIsLoadingLocationsEvolution(true);

            const response = await fetchWithAuth(`${call}/get-locations-evolution`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    locationNames: locationNames,
                    startDate: startDate,
                    endDate: endDate,
                }),
            });
            const data = await response.json();

            setIsLoadingLocationsEvolution(false);

            setEvolutionLocations(data.locationEvolutions);
            setPeriodsEvolutionLocations(data.datePoints);

        } catch (error) {
            console.error('Error fetching evolution services:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const fetchEvolutionServices = async (startDate='2024-03-01', endDate = '2024-04-01') => {
        try {

            setIsLoadingServicesEvolution(true);
            const response = await fetchWithAuth(`${call}/get-services-evolution`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    locationNames: selectedLocations,
                    startDate: startDate,
                    endDate: endDate,
                }),
            });
            const data = await response.json();

            setIsLoadingServicesEvolution(false);

            setEvolutionServices(data.evolutionData);
            setPeriodsEvolutionServices(data.datePoints);

        } catch (error) {
            console.error('Error fetching evolution services:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    // Assuming you have the required information such as locationNames, startDate, endDate, and UserName
    const fetchTopLocations = async (locationNames, startDate='2024-03-01', endDate = '2024-04-01') => {
        const requestData = {
        locationNames: locationNames,
        startDate: startDate,
        endDate: endDate
        };

        setIsLoadingTopLocations(true);
    
        const topLocationsAfter = await fetchWithAuth(`${call}/get-top-locations`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the fetch operation:', error);
            });

        // Convert startDate and endDate to Date objects
        const startDateObj = newDate(startDate);
        const endDateObj = newDate(endDate);

        // Calculate the interval between startDate and endDate in milliseconds
        const intervalMs = endDateObj - startDateObj;

        // Calculate newStartDate by subtracting the interval from startDate
        const newStartDateObj = newDate(startDateObj.getTime() - intervalMs);

        // Format newStartDate back to 'YYYY-MM-DD' string format
        const newStartDate = newStartDateObj.toISOString().split('T')[0];

        // Create requestData object with updated startDate
        const requestDataNew = {
        locationNames: locationNames,
        startDate: newStartDate,
        endDate: startDate
        };

        const topLocationsBefore = await fetchWithAuth(`${call}/get-top-locations`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestDataNew)
            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the fetch operation:', error);
            });

            // Create topLocationsFinal with the trend column
            const topLocationsFinal = topLocationsAfter.map(afterItem => {
                const matchingBeforeItem = topLocationsBefore.find(beforeItem => beforeItem.name === afterItem.name);
                if (!matchingBeforeItem) return afterItem;

                const trend = afterItem.total_margin_per - matchingBeforeItem.total_margin_per;
                return {
                ...afterItem,
                trend
                };
            });

            setTopLocations(topLocationsFinal);

            setIsLoadingTopLocations(false);

    };

    // Assuming you have the required information such as locationNames, startDate, endDate, and UserName
    const fetchTopServices = (startDate='2024-03-01', endDate = '2024-04-01') => {
        const requestData = {
        locationNames: selectedLocations,
        startDate: startDate,
        endDate: endDate
        };

        setIsLoadingTopServices(true);
    
        fetchWithAuth(`${call}/get-top-services`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
        })
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setTopServices(data);
            setIsLoadingTopServices(false);
        })
        .catch(error => {
            // Handle errors
            console.error('There was a problem with the fetch operation:', error);
        });
    };
        
  

    const fetchLocations = () => {
        // Fetch locations data from the /locations API
        fetchWithAuth(`${call}/locations`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            // setLocations(data); // Update the locations state with the fetched data
            const locations_data = data.filter(item => item.coordinates != null);
            setLocations(locations_data);

            setGroups(groupLocationsByGroupName(locations_data));

            fetchTopLocations(locations_data.map(item => item.location_name), formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));
            fetchEvolutionLocations(locations_data.map(item => item.location_name), formatDateToUTCString(startDateCalendar), formatDateToUTCString(endDateCalendar));


          })
          .catch(error => console.error('Error fetching locations data:', error));
      };

    async function fetchTopManufacturers(startDate='2024-03-01', endDate = '2024-04-01') {
        try {
          const requestData = {
            locationNames: selectedLocations,
            startDate: startDate,
            endDate: endDate,
          };

          setIsLoadingTopManufacturers(true);
      
          const response = await fetchWithAuth(`${call}/top-manufacturers-report`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const data = await response.json();
          
          setIsLoadingTopManufacturers(false);

          setTopManufacturers(data);
          return data;
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          throw error;
        }
    }

    async function fetchTopProducts(startDate='2024-03-01', endDate='2024-04-01') {
        try {
            const requestData = {
                locationNames: selectedLocations,
                startDate: startDate,
                endDate: endDate,
            };

            setIsLoadingTopProducts(true);
    
            const response = await fetchWithAuth(`${call}/top-products-usage-report`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();

            setIsLoadingTopProducts(false);
    
            // Update your state or do something with the data as needed
            setTopProducts(data);
    
            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }
    
      
        

    // const locations = [
    //     // Northeast
    //     { name: 'New York City', coordinates: [-74.006, 40.7128], info: 'Info about New York', region: 'Northeast', revenue: 200000, cogs: 150000 },
    //     { name: 'Philadelphia', coordinates: [-75.1652, 39.9526], info: 'Info about Philadelphia', region: 'Northeast', revenue: 180000, cogs: 140000 },
    //     { name: 'Boston', coordinates: [-71.0589, 42.3601], info: 'Info about Boston', region: 'Northeast', revenue: 160000, cogs: 120000 },
    //     { name: 'Washington D.C.', coordinates: [-77.0369, 38.9072], info: 'Info about Washington D.C.', region: 'Northeast', revenue: 150000, cogs: 110000 },
      
    //     // Midwest
    //     { name: 'Chicago', coordinates: [-87.6298, 41.8781], info: 'Info about Chicago', region: 'Midwest', revenue: 140000, cogs: 100000 },
    //     { name: 'Columbus', coordinates: [-83.0007, 39.9612], info: 'Info about Columbus', region: 'Midwest', revenue: 120000, cogs: 90000 },
    //     { name: 'Indianapolis', coordinates: [-86.1581, 39.7684], info: 'Info about Indianapolis', region: 'Midwest', revenue: 100000, cogs: 80000 },
      
    //     // South
    //     { name: 'Houston', coordinates: [-95.3698, 29.7604], info: 'Info about Houston', region: 'South', revenue: 180000, cogs: 130000 },
    //     { name: 'Phoenix', coordinates: [-112.074, 33.4484], info: 'Info about Phoenix', region: 'South', revenue: 160000, cogs: 120000 },
    //     { name: 'Dallas', coordinates: [-96.7969, 32.7767], info: 'Info about Dallas', region: 'South', revenue: 140000, cogs: 110000 },
    //     { name: 'San Antonio', coordinates: [-98.4936, 29.4241], info: 'Info about San Antonio', region: 'South', revenue: 120000, cogs: 100000 },
    //     { name: 'Austin', coordinates: [-97.7431, 30.2672], info: 'Info about Austin', region: 'South', revenue: 100000, cogs: 90000 },
    //     { name: 'Jacksonville', coordinates: [-81.6557, 30.3322], info: 'Info about Jacksonville', region: 'South', revenue: 80000, cogs: 70000 },
    //     { name: 'Charlotte', coordinates: [-80.8431, 35.2271], info: 'Info about Charlotte', region: 'South', revenue: 70000, cogs: 60000 },
      
    //     // West
    //     { name: 'Los Angeles', coordinates: [-118.2437, 34.0522], info: 'Info about Los Angeles', region: 'West', revenue: 160000, cogs: 120000 },
    //     { name: 'San Francisco', coordinates: [-122.4194, 37.7749], info: 'Info about San Francisco', region: 'West', revenue: 140000, cogs: 100000 },
    //     { name: 'San Diego', coordinates: [-117.1611, 32.7157], info: 'Info about San Diego', region: 'West', revenue: 120000, cogs: 90000 },
    //     { name: 'Seattle', coordinates: [-122.3321, 47.6062], info: 'Info about Seattle', region: 'West', revenue: 100000, cogs: 80000 },
    //     { name: 'Denver', coordinates: [-104.9903, 39.7392], info: 'Info about Denver', region: 'West', revenue: 90000, cogs: 70000 },
    //     { name: 'San Jose', coordinates: [-121.8863, 37.3382], info: 'Info about San Jose', region: 'West', revenue: 80000, cogs: 60000 },
    //   ];
      

    const dummyManufacturers = [
        { id: 1, name: 'Apple', sales: 15000 },
        { id: 2, name: 'Samsung', sales: 13000 },
        { id: 3, name: 'Microsoft', sales: 12000 },
        { id: 4, name: 'Google', sales: 11000 },
        { id: 5, name: 'Amazon', sales: 10000 },
        { id: 6, name: 'Toyota', sales: 9000 },
        { id: 7, name: 'Ford', sales: 8500 },
        { id: 8, name: 'Tesla', sales: 8000 },
        { id: 9, name: 'Sony', sales: 7500 },
        { id: 10, name: 'LG', sales: 7000 },
        // Add more dummy data as needed
    ];
      
    const dummyProducts = [
        { id: 1, name: 'iPhone 13 Pro', sales: 7500 },
        { id: 2, name: 'Samsung Galaxy S21', sales: 7000 },
        { id: 3, name: 'Microsoft Surface Laptop', sales: 6500 },
        { id: 4, name: 'Google Pixel 6', sales: 6000 },
        { id: 5, name: 'Amazon Echo Dot', sales: 5500 },
        { id: 6, name: 'Toyota Camry', sales: 5000 },
        { id: 7, name: 'Ford F-150', sales: 4500 },
        { id: 8, name: 'Tesla Model 3', sales: 4000 },
        { id: 9, name: 'Sony PlayStation 5', sales: 3500 },
        { id: 10, name: 'LG OLED TV', sales: 3000 },
        // Add more dummy data as needed
    ];


    const bestServicesData = [
        { id: 1, name: 'Service A', category: 'Category 1', usage: 500, revenue: 10000, cogs: 6000 },
        { id: 2, name: 'Service B', category: 'Category 2', usage: 700, revenue: 15000, cogs: 8000 },
        { id: 3, name: 'Service C', category: 'Category 1', usage: 400, revenue: 12000, cogs: 7000 },
        { id: 4, name: 'Service D', category: 'Category 3', usage: 300, revenue: 8000, cogs: 4000 },
        { id: 5, name: 'Service E', category: 'Category 2', usage: 600, revenue: 14000, cogs: 7500 },
        { id: 6, name: 'Service F', category: 'Category 1', usage: 450, revenue: 11000, cogs: 6500 },
        { id: 7, name: 'Service G', category: 'Category 2', usage: 550, revenue: 13500, cogs: 7200 },
        { id: 8, name: 'Service H', category: 'Category 1', usage: 300, revenue: 9500, cogs: 5500 },
        { id: 9, name: 'Service I', category: 'Category 3', usage: 200, revenue: 7000, cogs: 3500 },
        { id: 10, name: 'Service J', category: 'Category 2', usage: 800, revenue: 16000, cogs: 8500 },
        // Add more data as needed
    ];
      

    const usCities = locations.filter(location => location.coordinates[0] > -130 && location.coordinates[0] < -60);

    // Update handleMarkerClick to toggle selected markers
    const handleMarkerClick = (location) => {

        const isSelected = selectedMarkers.some((marker) => marker.name === location.name);

        console.log("isSelected")
        console.log(isSelected)

        if (isSelected) {
            setSelectedMarkers(selectedMarkers.filter((marker) => marker.name !== location.name));
        } else {
            setSelectedMarkers([...selectedMarkers, location]);
        }
    };

    const handleLocations = (name) => {

        if (mode === 'location'){
            setSelectedLocation(name);
            if (name === 'All'){
                setSelectedLocations(locations.map(item => item.location_name))
            }
            else{
                setSelectedLocations([name]);
            }
        }
        else{
            setSelectedGroup(name);
            if (name === 'All'){
                setSelectedLocations(locations.map(item => item.location_name))
            }
            else{
                setSelectedLocations(groups[name]);
            }
        }
        
    };

    const handleMode = (option) => {

        setMode(option);
        if (option === 'location'){
            setSelectedLocation('VIO Strongsville');
            setSelectedLocations(['VIO Strongsville']);
        }
        else{
            setSelectedGroup('Elevate Beauty Partners');
            setSelectedLocations(['VIO Strongsville']);
        }
        
    };

    const updateStartEndDate = (startDate, endDate) => {

        fetchTopManufacturers(formatDateToUTCString(startDate), formatDateToUTCString(endDate));
        fetchTopProducts(formatDateToUTCString(startDate), formatDateToUTCString(endDate));
        fetchTopServices(formatDateToUTCString(startDate), formatDateToUTCString(endDate));
        fetchTopLocations(locations.map(item => item.location_name), formatDateToUTCString(startDate), formatDateToUTCString(endDate));
        fetchEvolutionServices(formatDateToUTCString(startDate), formatDateToUTCString(endDate))
        fetchEvolutionLocations(locations.map(item => item.location_name), formatDateToUTCString(startDate), formatDateToUTCString(endDate))

    };

    // const handleMapChange = (type) => {
    //     if (type === 'us') {
    //         center = [0, 0];
    //         zoom = 1; // Default zoom for the US map
    //     } else {
    //         center = [20, 0];
    //         zoom = 1;
    //     }
    //     setSelectedCity([]);
    //     setMapType(type);
    // };

    const tableStyle = {         
        width: '100%', 
        borderCollapse: 'separate', 
        borderSpacing: 0, 
        position: 'relative'
    };

    const theadStyle = {         
        position: 'sticky', // Stick the header
        top: 0, // Stick to the top of the table
    };

    const divStyle = { 
        border: '2px solid #87D3D7', 
        display: 'flex', 
        justifyContent: 'left', 
        flexDirection: 'column', 
        width: '550px', 
        maxHeight: '400px', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
    };

    const thStyle = {
        borderBottom:'2px solid black', padding: '10px'
    };
    
    const tdStyle = {
        padding: '10px'
    };
      
    const TopManufacturersTable = () => {
        
        return (
          <div style={{textAlign: 'center', marginRight: '100px'}}>
            <h2 style={{color: '#F49C7D'}}>Top Manufacturers</h2>
            <div style={divStyle}>
                <table style={tableStyle}>                
                    <thead style={theadStyle}>
                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                        <th style={thStyle}>Ranking</th>
                        <th style={thStyle}>Name</th>
                        <th style={thStyle}>Purchases</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topManufacturers.map((manufacturer, index) => (
                        <tr key={manufacturer.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                            <td style={tdStyle}>{index + 1}</td>
                            <td style={tdStyle}>{manufacturer.name}</td>
                            <td style={tdStyle}>${manufacturer.total_order_value.toLocaleString()}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        );
    };
      
    const TopProductsTable = () => {
        // Sort products by sales in descending order
        const sortedProducts = dummyProducts.sort((a, b) => b.sales - a.sales);
        
        return (
        <div style={{textAlign: 'center'}}>
            <h2 style={{color: '#F49C7D'}}>Top Products</h2>
            <div style={divStyle}>
                <table style={tableStyle}>
                <thead style={theadStyle}>
                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                        <th style={thStyle}>Ranking</th>
                        <th style={thStyle}>Name</th>
                        <th style={thStyle}>Purchases</th>
                    </tr>
                </thead>
                <tbody>
                    {topProducts.slice(0,30).map((product, index) => (
                    <tr key={product.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                        <td style={tdStyle}>{index + 1}</td>
                        <td style={tdStyle}>{product.product_name}</td>
                        <td style={tdStyle}>${product.total_price.toLocaleString()}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
        </div>
        );
    };

    const BestServicesTable = () => {      
        return (
          <div style={{textAlign: 'center'}}>
            <h2 style={{color: '#F49C7D'}}>Best Performing Services</h2>
            <div style={{...divStyle, width: '1200px'}}>
                <table style={tableStyle}>
                <thead style={theadStyle}>
                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                        <th style={thStyle}>Ranking</th>
                        <th style={{...thStyle, textAlign: 'left'}}>Name</th>
                        <th style={thStyle}>Category</th>
                        <th style={thStyle}>Usage</th>
                        <th style={thStyle}>Revenue</th>
                        <th style={thStyle}>Discounts</th>
                        <th style={thStyle}>COGS</th>
                        <th style={thStyle}>Margin</th>
                        <th style={thStyle}>Margin %</th>
                    </tr>
                </thead>
                <tbody>
                    {topServices.map((service, index) => (
                    <tr key={service.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                        <td style={{...tdStyle}}>{index + 1 }</td>
                        <td style={{...tdStyle, textAlign: 'left'}}>{service.service_name}</td>
                        <td style={tdStyle}>{service.service_category}</td>
                        <td style={tdStyle}>{service.service_usage}</td>
                        <td style={tdStyle}>${service.sale_price.toLocaleString()}</td>
                        <td style={tdStyle}>${service.discount.toLocaleString()}</td>
                        <td style={tdStyle}>${service.service_cogs.toLocaleString()}</td>
                        <td style={tdStyle}>${service.margin.toLocaleString()}</td>
                        <td style={tdStyle}>
                        {service.margin_percentage ? service.margin_percentage.toFixed(2) + '%' : ''}
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
          </div>
        );
    };

    const LocationRankingTable = () => {
        return (
            <div style={{ textAlign: 'center'}}>
                <h2 style={{color: '#F49C7D'}}>Location Revenue Ranking</h2>
                <div style={{...divStyle, width: '1200px'}}>
                    <table style={tableStyle}>
                    <thead style={theadStyle}>
                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                            <th style={thStyle}>Ranking</th>
                            <th style={thStyle}>Name</th>
                            <th style={thStyle}>Revenue</th>
                            <th style={thStyle}>Discount</th>
                            <th style={thStyle}>COGS</th>
                            <th style={thStyle}>Margin</th>
                            <th style={thStyle}>Margin %</th>
                            <th style={thStyle}>Trend %</th>
                        </tr>
                        </thead>
                        <tbody>
                        {topLocations.map((location, index) => (
                            <tr key={location.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                <td style={tdStyle}>{index + 1}</td>
                                <td style={tdStyle}>{location.name}</td>
                                <td style={tdStyle}>${location.total_revenue ? location.total_revenue.toLocaleString() : location.total_revenue}</td>
                                <td style={tdStyle}>${location.total_discount ? location.total_discount.toLocaleString() : location.total_discount}</td>
                                <td style={tdStyle}>${location.total_cogs ? location.total_cogs.toLocaleString() : location.total_cogs}</td>
                                <td style={tdStyle}>${location.total_margin ? location.total_margin.toLocaleString() : location.total_margin}</td>
                                <td style={tdStyle}>{location.total_margin_per.toFixed(2)}%</td>
                                <td style={tdStyle}>
                                {location.trend >= 0 ? (
                                    <span style={{ color: 'green' }}>
                                    <FontAwesomeIcon icon={faLongArrowAltUp} /> {Math.abs(location.trend).toFixed(2)}%
                                    </span>
                                ) : (
                                    <span style={{ color: 'red' }}>
                                    <FontAwesomeIcon icon={faLongArrowAltDown} /> {Math.abs(location.trend).toFixed(2)}%
                                    </span>
                                )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
    

    const serviceData = [
        { id: 1, name: 'Service A', revenue: [1000, 1500, 2000, 1800, 2200], margin: [30, 35, 40, 38, 42] },
        { id: 2, name: 'Service B', revenue: [1200, 1400, 1800, 1600, 2000], margin: [25, 28, 30, 29, 32] },
        { id: 3, name: 'Service C', revenue: [800, 1000, 1200, 1100, 1300], margin: [20, 22, 25, 23, 27] },
        // Add more data as needed
      ];

    const getTop10ServicesByRevenue = (evolutionData) => {
    return evolutionData
        .slice() // Make a copy to avoid modifying the original array
        .sort((a, b) => b.revenue.reduce((acc, val) => acc + val, 0) - a.revenue.reduce((acc, val) => acc + val, 0))
        .slice(0, 10);
    };

    const getBottom10ServicesByRevenue = (evolutionData) => {
        return evolutionData
            .slice() // Make a copy to avoid modifying the original array
            .sort((a, b) => a.revenue.reduce((acc, val) => acc + val, 0) - b.revenue.reduce((acc, val) => acc + val, 0))
            .slice(0, 10);
    };

    const getBiggestHighReturnServices = (evolutionData) => {
        return evolutionData
            .slice() // Make a copy to avoid modifying the original array
            .sort((a, b) => {
                const sumMarginA = a.margin.reduce((acc, val) => acc + val, 0);
                const sumMarginB = b.margin.reduce((acc, val) => acc + val, 0);
                return sumMarginB - sumMarginA;
            })
            .slice(0, 10);
    };

    const getBiggestLowReturnServices = (evolutionData) => {
        return evolutionData
            .slice() // Make a copy to avoid modifying the original array
            .sort((a, b) => {
                const sumMarginA = a.margin.reduce((acc, val) => acc + val, 0);
                const sumMarginB = b.margin.reduce((acc, val) => acc + val, 0);
                return sumMarginA - sumMarginB;
            })
            .slice(0, 10);
    };
    
      
    let chart; 
    
    
    const getRandomColor = () => {
        const baseColors = ['#F49C7D', '#87D3D7', '#31bac1'];
        const baseColor = baseColors[Math.floor(Math.random() * baseColors.length)];
        const range = 20; // Adjust this value to control the color range
        const baseRGB = hexToRGB(baseColor);
        
        const randomColor = {
            r: Math.min(Math.max(Math.floor(baseRGB.r + (Math.random() * range * 2) - range), 0), 255),
            g: Math.min(Math.max(Math.floor(baseRGB.g + (Math.random() * range * 2) - range), 0), 255),
            b: Math.min(Math.max(Math.floor(baseRGB.b + (Math.random() * range * 2) - range), 0), 255)
        };
    
        return rgbToHex(randomColor);
    };
    
    const hexToRGB = (hex) => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };
    
    const rgbToHex = (rgb) => {
        return "#" + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
    };

    const ServiceGraph = () => {
        const [selectedModeData, setSelectedModeData] = useState('services');
        const [selectedData, setSelectedData] = useState('revenue');
        const [selectedDataOption, setSelectedDataOption] = useState('top10');
        const [evolutionData, setEvolutionData] = useState(getTop10ServicesByRevenue(evolutionServices));

        const configurationsGraph = {
            type: 'line',
            data: {
                labels: [], // Use periods as labels for x-axis
                datasets: [],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: 'Time', // X-axis title
                        },
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: (selectedData === 'revenue' ? 'Revenue ( $ )': 'Margin ( $ )'), // Y-axis title
                        },
                    },
                },
            },
        };

        const handleModeData = (option) => {
            setSelectedModeData(option);
            setSelectedDataOption('top10');

            if (option === 'services'){
                setEvolutionData(getTop10ServicesByRevenue(evolutionServices))
            }
            else{
                setEvolutionData(getTop10ServicesByRevenue(evolutionLocations))
            }
            
        };

        const handleDataOption = (option) => {
            setSelectedDataOption(option);

            if (selectedModeData === 'services'){
                if (option === 'biggestHigh') {
                    setEvolutionData(getBiggestHighReturnServices(evolutionServices));
                } else if (option === 'biggestLow') {
                    setEvolutionData(getBiggestLowReturnServices(evolutionServices));
                } else if (option === 'bottom10') {
                    setEvolutionData(getBottom10ServicesByRevenue(evolutionServices));
                } else {
                    setEvolutionData(getTop10ServicesByRevenue(evolutionServices));          
                }
            }
            else{
                if (option === 'biggestHigh') {
                    setEvolutionData(getBiggestHighReturnServices(evolutionLocations));
                } else if (option === 'biggestLow') {
                    setEvolutionData(getBiggestLowReturnServices(evolutionLocations));
                } else if (option === 'bottom10') {
                    setEvolutionData(getBottom10ServicesByRevenue(evolutionLocations));
                } else {
                    setEvolutionData(getTop10ServicesByRevenue(evolutionLocations));          
                }
            }

            
        };

        const chartRef = useRef(null);
    
        useEffect(() => {
            const ctx = chartRef.current.getContext('2d');
            if (chart) {
                chart.destroy();
            }
            chart = new Chart(ctx, configurationsGraph);
    
            return () => {
                if (chart) {
                    chart.destroy();
                }
            };
        }, [selectedData]);
    
        useEffect(() => {
            if (chart) {
                chart.data.datasets = [];
                chart.data.labels = ((selectedModeData === 'services') ? periodsEvolutionServices.slice(1) : periodsEvolutionLocations.slice(1)) || []; // Set periods (dates) as labels for x-axis

                evolutionData.slice(0, 10).forEach(service => {
                    chart.data.datasets.push({
                        label: service.name,
                        data: service[selectedData].map((value, index) => ({
                            x: service.periods[index],
                            y: value,
                        })),
                        borderColor: getRandomColor(),
                    });
                });
                chart.update();
            }
        }, [chart, selectedData, evolutionData]);
    
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ color: '#F49C7D' }}>Historic Performances</h2>
                </div>
                <select
                    id="dataSelect"
                    value={selectedModeData}
                    onChange={(e) => handleModeData(e.target.value)}
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        padding: '5px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '100px',
                    }}
                >
                    <option value="services">Services</option>
                    <option value="locations">Locations</option>
                </select>
                <select
                    id="dataSelect"
                    value={selectedData}
                    onChange={(e) => setSelectedData(e.target.value)}
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        padding: '5px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '100px',
                    }}
                >
                    <option value="revenue">Revenue</option>
                    <option value="margin">Margin</option>
                </select>
                <select
                    id="dataSelect"
                    value={selectedDataOption}
                    onChange={(e) => handleDataOption(e.target.value)}
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        padding: '5px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '100px',
                    }}
                >
                    <option value="top10">Top 10</option>
                    <option value="bottom10">Bottom 10</option>
                    <option value="biggestHigh">Biggest High</option>
                    <option value="biggestLow">Biggest Low</option>
                </select>
                <canvas ref={chartRef} width="1200" height="400"></canvas>
            </div>
        );
    };
    

    let piechart; 

    const ServiceCategoryPieChart = () => {
        const [selectedDataPieChart, setSelectedDataPieChart] = useState('usage');
        const chartRef = useRef(null);

        const serviceCategories = topServices.map(service => service.service_category);
        const uniqueCategories = [...new Set(serviceCategories)]; // Get unique categories
    
        const categoryData = uniqueCategories.map(category => {
          const categoryServices = topServices.filter(service => service.service_category === category);
          const categoryRevenue = categoryServices.reduce((total, service) => total + service.sale_price, 0);
          const categoryUsage = categoryServices.reduce((total, service) => total + service.service_usage, 0);
          const categoryCOGS = categoryServices.reduce((total, service) => total + service.service_cogs, 0);
    
          return {
            category,
            revenue: categoryRevenue,
            usage: categoryUsage,
            cogs: categoryCOGS,
          };
        });
    
        const totalRevenue = categoryData.reduce((total, item) => total + item.revenue, 0);
        const totalCOGS = categoryData.reduce((total, item) => total + item.cogs, 0);
        const totalUsage = categoryData.reduce((total, item) => total + item.usage, 0);
    
        const categoryDataWithPercentage = categoryData.map(item => ({
            ...item,
            revenuePercentage: (item.revenue / totalRevenue) * 100,
            cogsPercentage: (item.cogs / totalCOGS) * 100,
            usagePercentage: (item.usage / totalUsage) * 100,
        }));
    
        const pieChartConfig = {
            type: 'pie',
            data: {
                labels: categoryDataWithPercentage.map(item => item.category),
                datasets: [
                    {
                        data: categoryDataWithPercentage.map(item => item.revenuePercentage),
                        backgroundColor: categoryDataWithPercentage.map(() => getRandomColor()),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true
            },
        };
    
        const updateChartData = () => {
            const updatedPieChartConfig = {
                ...pieChartConfig,
                data: {
                    labels: categoryDataWithPercentage.map(item => item.category),
                    datasets: [
                        {
                            data: categoryDataWithPercentage.map(item => {
                                if (selectedDataPieChart === 'cogs') {
                                    return item.cogsPercentage; // Update data based on COGS
                                } else if (selectedDataPieChart === 'revenue') {
                                    return item.revenuePercentage; // Update data based on Revenue
                                } else {
                                    return item.usagePercentage; // Default to Usage
                                }
                            }),
                            backgroundColor: categoryDataWithPercentage.map(() => getRandomColor()),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: context => {
                                    const category = context.label;
                                    const percentage = context.parsed;
                                    return `${category}: ${percentage.toFixed(2)}%`;
                                }
                            }
                        }
                    }
                },
            };
    
            if (piechart) {
                piechart.destroy();
            }
    
            const ctx = chartRef.current.getContext('2d');
            piechart = new Chart(ctx, updatedPieChartConfig);
        };
    
        useEffect(() => {
            updateChartData();
        }, [selectedDataPieChart]);
    
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ color: '#F49C7D' }}>Service Categories</h2>
                </div>
                <select
                    id="dataSelect"
                    value={selectedDataPieChart}
                    onChange={(e) => setSelectedDataPieChart(e.target.value)}
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        padding: '5px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '100px',
                    }}
                >
                    <option value="usage">Usage</option>
                    <option value="cogs">COGS</option>
                    <option value="revenue">Revenue</option>
                </select>
                <canvas ref={chartRef} width="450" height="400"></canvas>
            </div>
        );
    };

    const ProductCategoryPieChart = () => {
        const chartRef = useRef(null);
        const [selectedDataType, setSelectedDataType] = useState('usage');
    
        // Get unique product categories
        const productCategories = topProducts.map(product => product.product_category);
        const uniqueCategories = [...new Set(productCategories)];
    
        // Calculate category data based on unique categories
        const categoryData = uniqueCategories.map(category => {
            const categoryProducts = topProducts.filter(product => product.product_category === category);
            const categoryRevenue = categoryProducts.reduce((total, product) => total + product.total_price, 0);
            const categoryUsage = categoryProducts.reduce((total, product) => total + product.total_usage, 0);
            const categoryCOGS = categoryProducts.reduce((total, product) => total + product.total_cogs, 0);
    
            return {
                category,
                revenue: categoryRevenue,
                usage: categoryUsage,
                cogs: categoryCOGS,
            };
        });
    
        const totalRevenue = categoryData.reduce((total, item) => total + item.revenue, 0);
        const totalCOGS = categoryData.reduce((total, item) => total + item.cogs, 0);
        const totalUsage = categoryData.reduce((total, item) => total + item.usage, 0);
    
        const categoryDataWithPercentage = categoryData.map(item => ({
            ...item,
            revenuePercentage: (item.revenue / totalRevenue) * 100,
            cogsPercentage: (item.cogs / totalCOGS) * 100,
            usagePercentage: (item.usage / totalUsage) * 100,
        }));
    
        const pieChartConfig = {
            type: 'pie',
            data: {
                labels: categoryDataWithPercentage.map(item => item.category),
                datasets: [
                    {
                        data: selectedDataType === 'price'
                            ? categoryDataWithPercentage.map(item => item.revenuePercentage)
                            : categoryDataWithPercentage.map(item => item.usagePercentage),
                        backgroundColor: categoryDataWithPercentage.map(() => getRandomColor()),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => {
                                const category = context.label;
                                const percentage = context.parsed;
                                return `${category}: ${percentage.toFixed(2)}%`;
                            }
                        }
                    }
                }
            },
        };
    
        const updateChartData = () => {
            const ctx = chartRef.current.getContext('2d');
            if (window.piechart) {
                window.piechart.destroy();
            }
            window.piechart = new Chart(ctx, pieChartConfig);
        };
    
        useEffect(() => {
            updateChartData();
        }, [selectedDataType]);
    
        return (
            <div style={{marginRight: '200px'}}>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ color: '#F49C7D' }}>Product Categories</h2>
                </div>
                <select
                    value={selectedDataType}
                    onChange={(e) => setSelectedDataType(e.target.value)}
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        padding: '5px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '100px',
                    }}
                >
                    <option value="usage">Usage</option>
                    <option value="price">Price</option>
                </select>
                <canvas ref={chartRef} width="450" height="400"></canvas>
            </div>
        );
    };
    

    return (
        <div ref={contentRef}>
            <ToastContainer />
            <h1 style={{ marginTop: '27px', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>VIO Corporate Dashboard</h1>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <select value={mapType} onChange={(e) => handleMapChange(e.target.value)}>
                    <option value="us">US Map</option>
                    <option value="world">World Map</option>
                </select> */}

                <div style={{ width: '100%', overflow: 'hidden', borderRadius: '5px', marginTop: '20px', marginBottom: '20px', backgroundColor: 'rgba(135, 211, 215, 0.4)' }}>
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%', height: '100%' }}>
                                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <ComposableMap projection={mapType === 'us' ? 'geoAlbersUsa' : 'geoEqualEarth'}>
                                        <ZoomableGroup center={center} zoom={zoom} disablePanning>
                                            <Geographies geography={mapType === 'us' ? '/us-map.json' : '/world-map.json'}>
                                                {({ geographies }) =>
                                                    geographies && geographies.map((geo) => (
                                                        <Geography 
                                                            key={geo.rsmKey} 
                                                            geography={geo} 
                                                            fill="#87D3D7" 
                                                            stroke="#31bac1"
                                                        />
                                                    ))
                                                }
                                            </Geographies>
                                            {topLocations && topLocations.length !== 0 && (mapType === 'us' ? topLocations : locations).map((location, index) => (
                                                <Marker key={index} coordinates={JSON.parse(location.coordinates)}>
                                                    <g
                                                        fill="none"
                                                        stroke="#F49C7D"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        transform="translate(-8, -16)"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleMarkerClick(location)}
                                                    >
                                                        <circle cx="8" cy="6.67" r={2} />
                                                        <path d="M8 14.47C11.53 11.33 13.33 8.67 13.33 6.67a5.33 5.33 0 1 0-10.67 0c0 2 1.8 4.67 5.33 7.8z" />
                                                    </g>
                                                    <text
                                                        textAnchor="middle"
                                                        y={8}
                                                        style={{
                                                            fontFamily: "system-ui",
                                                            fill: "#5D5A6D",
                                                            fontSize: '8px',
                                                            cursor: 'pointer' // Add cursor style here
                                                        }}
                                                        onClick={() => handleMarkerClick(location)}
                                                    >
                                                        {location.name}
                                                    </text>
                                                </Marker>
                                            ))}
                                        
                                            {selectedMarkers.map((selectedMarker) => (
                                                <Marker key={selectedMarker.id} coordinates={JSON.parse(selectedMarker.coordinates)}>
                                                    <g>
                                                        <rect
                                                            x="-10" 
                                                            y="-10" 
                                                            width="100" 
                                                            height="115" 
                                                            fill="white"
                                                            stroke="#F49C7D" // Border color
                                                            strokeWidth="2" // Border width
                                                            rx="5" 
                                                            onClick={() => handleMarkerClick(selectedMarker)}
                                                        />
                                                        <text
                                                            y={8}
                                                            style={{ fontWeight: 'bold', fontSize: '11px' }}
                                                        >
                                                            {selectedMarker.name}                                                        
                                                        </text>
                                                        <text
                                                            y={25}
                                                            style={{ fontWeight: 'bold', fontSize: '8px' }}
                                                        >
                                                            {selectedMarker.shipping_state}                                                        
                                                        </text>
                                                        <text
                                                            y={40}
                                                            style={{ fontSize: '8px' }}
                                                        >
                                                            Revenue: ${selectedMarker.total_revenue.toLocaleString()}                                                        
                                                        </text>
                                                        <text
                                                            y={55}
                                                            style={{ fontSize: '8px' }}
                                                        >
                                                            Discount: ${selectedMarker.total_discount.toLocaleString()}                                                        
                                                        </text>
                                                        <text
                                                            y={70}
                                                            style={{ fontSize: '8px' }}
                                                        >
                                                            COGS: ${selectedMarker.total_cogs.toLocaleString()}                                                        
                                                        </text>
                                                        {selectedMarker.total_margin_per && (
                                                            <>
                                                                {selectedMarker.total_margin_per > 22 ? (
                                                                    <text 
                                                                        y={85} 
                                                                        fill='#119b1a'
                                                                        style={{ fontSize: '8px' }} 
                                                                    >
                                                                        Margin: {selectedMarker.total_margin_per.toFixed(2)}%
                                                                    </text>
                                                                ) : (
                                                                    <text 
                                                                        y={85} 
                                                                        fill='#e9453b' 
                                                                        style={{ fontSize: '8px' }}
                                                                    >
                                                                        Margin: {selectedMarker.total_margin_per.toFixed(2)}%
                                                                    </text>                                                            
                                                                )}
                                                            </>
                                                        )}                                                         
                                                    </g>
                                                </Marker>
                                            ))}
                                        </ZoomableGroup>
                                        </ComposableMap>
                                        
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <br/>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <select
                        value={mode}
                        onChange={(e) => handleMode(e.target.value)}
                        style={{
                        marginRight: '20px',
                        padding: '10px',
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #87D3D7',
                        fontStyle: 'italic',
                        width: '300px',
                        }}
                    >
                        <option value="location">Per Location</option>
                        <option value="group">Per Group</option>
                    </select>
                    
                    { mode === 'location' ? 
                        (
                            <select
                                value={selectedLocation}
                                onChange={(e) => handleLocations(e.target.value)}
                                style={{
                                marginRight: '20px',
                                padding: '10px',
                                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                                border: '1px solid #87D3D7',
                                fontStyle: 'italic',
                                width: '300px',
                                }}
                            >
                                <option value="All">All Locations</option>
                                {locations.map(location => (
                                <option key={location.location_name} value={location.location_name}>{location.location_name}</option>
                                ))}
                            </select>
                        )
                        :
                        (
                            <select
                                value={selectedGroup}
                                onChange={(e) => handleLocations(e.target.value)}
                                style={{
                                marginRight: '20px',
                                padding: '10px',
                                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                                border: '1px solid #87D3D7',
                                fontStyle: 'italic',
                                width: '300px',
                                }}
                            >
                                <option value="All">All Groups</option>
                                {Object.keys(groups).map(group => (
                                <option key={group} value={group}>{group}</option>
                                ))}
                            </select>
                        )
                    }

                    {isLoadingTopManufacturers || isLoadingTopProducts || isLoadingTopServices || isLoadingTopLocations || isLoadingServicesEvolution || isLoadingLocationsEvolution ? (
                        <ClipLoader
                        color='#F49C7D'
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                        ''
                    )}
                </div>
                
                <br/>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{marginRight:'20px'}}>
                        <p style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }} >Start Date:</p>
                        <DatePicker 
                            selected={startDateCalendar} 
                            onChange={(date) => setStartDateCalendar(date)} 
                        />
                    </div>
                    <div style={{marginRight:'20px'}}>
                        <p style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }} >End Date:</p>
                        <DatePicker 
                            selected={endDateCalendar} 
                            onChange={(date) => setEndDateCalendar(date)} 
                        />
                    </div>
                    <div style={{marginTop:'50px'}}>
                        <button 
                            onClick={() => updateStartEndDate(startDateCalendar, endDateCalendar)} 
                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Submit
                        </button>
                    </div>
                </div>

                <br/>

                <LocationRankingTable />

                <br/>
                <br/>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ServiceGraph/>
                </div>

                <br/>

                <div
                    style = {{display: 'flex'}}
                >
                    <TopManufacturersTable />
                    <TopProductsTable />
                </div>
                
                <br/>

                <BestServicesTable />

                <br/>
                <br/>

                <div style={{ display: 'flex', alignItems: 'center' }}>                    
                    <ProductCategoryPieChart />
                    <ServiceCategoryPieChart />
                </div>
                
                <br/>

            </div>
        </div>
    );
}

export default Corporate;
