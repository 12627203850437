import { fetchWithAuth } from '../authentication/authenticationUtils';

export const webSearch = async (query) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/search-google-shopping?q=${ encodeURIComponent(query) }`);
        const data = await response.json();

        const shoppingResults = data.shopping_results;
        if (shoppingResults) {
            const processedResults = shoppingResults.map(item => ({
                ...item,
                price: parseFloat(item.price.match(/[0-9.]+/)[0]),
                minPrice: (parseFloat(item.price.match(/[0-9.]+/)[0]) * .9).toFixed(2),
                maxPrice: (parseFloat(item.price.match(/[0-9.]+/)[0]) * 1.25).toFixed(2)
            }));
            return processedResults;
        }
        else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching search results:', error);
    }
};

export const getGlobalInventory = async (locationName, setGlobalInventory) => {
    try {
        const requestBody = JSON.stringify({location_name: locationName});
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get-global-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();
        setGlobalInventory(data)
        return data
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};

export const addToInventory = async (productId, locationName, inventoryQuantity, productClass, username) => {
    try {
        const requestBody = JSON.stringify({productID: productId, selectedLocation: locationName, new_inventory_quantity: inventoryQuantity, product_class: productClass, username});
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/insert-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();
        if (data.affectedRows > 0) {
            return true;
        }
        else return false;
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};

export const getProductClass = async (productId) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get-product-class/${ productId }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();

        return data?.class ? data.class : 3; // default to 3
    } catch (error) {
        console.error('Error getting product class:', error);
        return 3; // default to 3
    }
};

export const fetchRecommendationsPredictions = async (location_name, product_id, setRecommendationPrediction) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/recommendation-predictions`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location_name,
                product_id
            }),
        });

        if (!response.ok) {
            throw new Error('Error fetching recommendation predictions');
        }

        const data = await response.json();

        setRecommendationPrediction(data);
    } catch (error) {
        console.error('Error fetching recommendation predictions:', error);
    }
};

export const editInventoryQuantity = async (productId, newQuantity, locationName) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/edit-quantity/${ productId }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({newQuantity, locationName}),
        });

        if (!response.ok) return false;
        return true
    } catch (error) {
        console.error('Error editing inventory quantity:', error);
        return false
    }
}

export const trackInventoryEdit = async (productId, newQuantity, originalQuantity, locationName, username, origin) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/insert-tracking-data/${ productId }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName, username, product_id: productId, old_inventory_quantity: originalQuantity, new_inventory_quantity: newQuantity, origin}),
        });

        if (!response.ok) return false;
        return true;
    } catch (error) {
        console.error('Error editing inventory quantity:', error);
        return false;
    }
};

export const updateSpotcheckDate = async (productId, locationName) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/update-spotcheck-date`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({productId, locationName}), // Include productId and locationName in the request body
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error || 'Failed to update spotcheck status');
        }
        return true
    } catch (error) {
        console.error('Error updating spotcheck status:', error.message);
        return false;
    }
};

// Handles the edit quantity button's logic, sends a PUT request to the server to make necessary SQL commands to edit quantity
export const handleEditQuantity = async ({locationName, productId, currentQuantity, quantity_conversion_rate, newQuantity, origin, username}) => {
    if (newQuantity !== null && !isNaN(newQuantity)) {
        try {
            // Calculate the new quantity based on the conversion rate
            const adjustedQuantity = parseFloat((newQuantity * quantity_conversion_rate).toFixed(1));

            // Send a PUT request to update the quantity on the server
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/edit-quantity/${ productId }`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({newQuantity: adjustedQuantity, locationName}),
            });

            if (!response.ok) {
                throw new Error(`Error updating quantity: ${ response.statusText }`);
            }
            // Prepare data for the /insert-tracking-data API
            const new_inventory_quantity = adjustedQuantity;

            const trackingResponse = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/insert-tracking-data/${ productId }`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location_name: locationName,
                    username,
                    product_id: productId,
                    old_inventory_quantity: currentQuantity,
                    new_inventory_quantity,
                    origin,
                }),
            });

            const trackingResult = await trackingResponse.json();

            if (!trackingResult.success) {
                throw new Error(data.error || 'Failed to track edit');

            }

            updateSpotcheckDate(productId, locationName);

            return true;
        } catch (error) {
            console.error('Error in edit quantity:', error);
        }
    }
};