import React, { useState } from 'react';
import Modal from 'react-modal';

const OwnerApprovalModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                content: {
                    width: '30%',
                    height: '35%',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: '8px',
                    padding: '20px',
                },
            }}
        >
            <h2>Order sent to owner for approval!</h2>
            <p>The request will be displayed along with the recommendations.</p>
            <button
                onClick={onClose}
                style={{
                    backgroundColor: '#87D3D7',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    marginTop: '20px',
                }}
            >
                Close
            </button>
        </Modal>
    );
};

export default OwnerApprovalModal;