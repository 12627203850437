import { fetchWithAuth } from '../authentication/authenticationUtils';

export const resolveFlag = async ({flagToResolve, username, explanation, brokenExpiredNumber, externalOrderNumber,
                                            gaveProductNumber, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber,
                                            freeProductNumber, deltaAfter, locationName}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/store-flags-resolution`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location_name: locationName,
                product_id: flagToResolve.product_id,
                tracking_ids: flagToResolve.tracking_ids,
                delta_quantity_initial: flagToResolve.total_delta,
                delta_quantity_before: deltaBeforeProcess,
                delta_quantity_after: deltaAfter,
                explanation,
                orders: orderDeliveredCheckNumber,
                moved: moveProductNumber,
                free_products: freeProductNumber,
                broken_expired: brokenExpiredNumber,
                gave_away: gaveProductNumber,
                external_orders: externalOrderNumber,
                username,
            }),
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to update spotcheck status');
        }

        return true;
    } catch (error) {
        console.error(`Error while submitting flagging resolution: ${ error }.`);
        return false;
    }
};
