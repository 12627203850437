import axios from 'axios';

export const axiosWithAuth = axios.create({
  withCredentials: true, // Automatically include cookies with each request
  baseURL: process.env.REACT_APP_BACKEND_URL, // Optional: Set a base URL for all requests
});

// Function to clear cookies by making a request to the backend logout endpoint
export const clearCookies = async () => {
  try {
    // Send a POST request to the backend logout endpoint
    const response = await axiosWithAuth.post(process.env.REACT_APP_BACKEND_URL + '/api/logout');

    if (response.status === 200) {
      console.log('Cookies cleared successfully.');
    } else {
      console.error('Failed to clear cookies.');
    }
  } catch (error) {
    console.error('Error clearing cookies:', error);
  }
};

export const fetchWithAuth = (url, options = {}) => {
    return fetch(url, {
        ...options,
        credentials: 'include',
    });
};

export const checkAuthStatus = async () => {
    try {
      const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/check-auth-cookies', {
        method: 'GET'
      });
  
      if (response.ok) {
        // If backend responds with status 200, the session is valid
        return true;
      } else {
        // If session is invalid, return false
        return false;
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      return false;
    }
};
  