import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {fetchManagerReviewStatus, updateManagerReviewStatus} from '../../utils/locations/locationsUtils';
import {fetchCheckDeliveries, updateDeliveryReviewStatus} from '../../utils/locations/locationsUtils';
import InfoPopup from '../../components/InfoPopup';

import { fetchWithAuth } from '../../utils/authentication/authenticationUtils';

const OrderSettings = ({locationName}) => {

    const [budget, setBudget] = useState('');
    const [isManagerReviewed, setIsManagerReviewed] = useState(false);
    const [isCheckDeliveries, setIsCheckDeliveries] = useState(false);

    const orderRecommendationContent = [
        <p key="recommendation-1">This setting enables managers to review recommendations directly on the Home page without placing an order immediately.</p>,
        <p key="recommendation-2">It allows owners to finalize decisions and handle the recommendations at a later time for better control.</p>,
    ];

    const orderDeliveryContent = [
        <p key="delivery-1">This setting allows certain staff members to manually verify if specific orders have been delivered.</p>,
        <p key="delivery-2">A checkbox is added next to each order on the Orders page, enabling easy confirmation of delivery status.</p>,
    ];    

    const fetchBudget = (setBudget) => fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/get-budget`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
            setBudget(data.budget);
        })
        .catch(error => console.error('Error fetching budget:', error));

    useEffect(() => {

        fetchBudget(setBudget);
        fetchManagerReviewStatus({locationName, setIsManagerReviewed})
        fetchCheckDeliveries({locationName, setIsCheckDeliveries})
    }, [locationName]);


    const handleSetBudget = async () => {
        if (budget <= 0){
            alert("The budget has to be strictly positive.");
            return;
        }

        fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/set-budget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, budget: budget }),
        })

        toast.success('Monthly budget updated successfully');
    };

    const toggleRecommendationReview = async () => {
        const newReviewSetting = !isManagerReviewed;
        const success = await updateManagerReviewStatus({locationName, isManagerReviewed: newReviewSetting});
        if(success) {
          setIsManagerReviewed(newReviewSetting);
          toast.success('Order Recommendation Review setting updated successfully');
        }
      }
    
    const toggleDeliveryCheck = async () => {
    const newDeliverySetting = !isCheckDeliveries;
    const success = await updateDeliveryReviewStatus({locationName, isCheckDeliveries: newDeliverySetting});
        if(success) {
            setIsCheckDeliveries(newDeliverySetting);
            toast.success('Order Delivery Review setting updated successfully');
        }
    }


    return(
        <div>

            <div>
                <h1 style={{ marginTop: '35px', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>
                    Orders Settings
                </h1>

                <h1 style={{ marginTop: '20px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>
                    Monthly Ordering Budget
                </h1>

                $<input
                    type="number"
                    style={{width: '150px', marginLeft: '2px'}}
                    value={budget}
                    onChange={e => setBudget(e.target.value)}
                    placeholder = 'Enter Monthy Budget'
                    min="0"
                />

                <button 
                    onClick={() => handleSetBudget()} 
                    style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px', marginLeft: '10px' }}
                >
                    Submit
                </button>
            </div>

            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ marginTop: '20px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>
                        Order Recommendation Review
                    </h1>
                    <InfoPopup title="Order Recommendation Review" content={orderRecommendationContent} />
                </div>

                <div style={{marginBottom: '20px', display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: '10px'}}>Disabled</span>
                    <label className="switch">
                    <input type="checkbox" checked={isManagerReviewed} onChange={toggleRecommendationReview} />
                    <span className="slider round"></span>
                    </label>
                    <span style={{marginLeft: '10px'}}>Enabled</span>
                </div>
            </div>

            <div>                
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ marginTop: '15px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>
                        Order Delivery Review
                    </h1>
                    <InfoPopup title="Order Delivery Review" content={orderDeliveryContent} />
                </div>

                <div style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: '10px'}}>Disabled</span>
                    <label className="switch">
                    <input type="checkbox" checked={isCheckDeliveries} onChange={toggleDeliveryCheck} />
                    <span className="slider round"></span>
                    </label>
                    <span style={{marginLeft: '10px'}}>Enabled</span>
                </div>
            </div>

            <ToastContainer/>

        </div>

    );
}

export default OrderSettings;
