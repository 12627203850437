import React from 'react';
import { formatDateText } from '../../utils/time_zones/TimeZones';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

const OrdersTable = ({
  orders, 
  handleActionsClick, 
  handleSortChange, 
  sortAttribute, 
  sortAsc, 
  setSortAttribute, 
  setSortAsc, 
  getArrow, 
  permissionCode, 
  isCheckDeliveries, 
  updateOrdersDeliveredManual,
  locationName
}) => {

  const actionsStatuses = ['Delivered', 'Shipped', 'Open', 'In Review', 'In Dispute'];

  return (
    <div style={{ 
        border: '2px solid #87D3D7', 
        display: 'flex', 
        justifyContent: 'left', 
        flexDirection: 'column', 
        width: '100%', 
        maxHeight: '610px', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      }}>

      <table style={{  
        
        width: '100%', 
        borderCollapse: 'separate', 
        borderSpacing: 0, 
        position: 'relative' // Ensure positioning context
      }}>
      <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f8f8f8' }}>
        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
          <th onClick={() => handleSortChange('order_date', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '15%' }}>
            Date {getArrow('order_date', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '30%' }}>
            Product Name {getArrow('product_name', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '20%' }}>
            Description {getArrow('product_description', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>
            Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('unit_price', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>
            Unit Price {getArrow('unit_price', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('order_quantity', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '5%', textAlign: 'center' }}>
            Quantity {getArrow('order_quantity', sortAttribute, sortAsc)}
          </th>
          <th onClick={() => handleSortChange('total', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>
            Total {getArrow('total', sortAttribute, sortAsc)}
          </th>
          <th style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Status</th>
          <th style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Actions</th>
          {isCheckDeliveries && 
            <th style={{ borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Delivered?</th>
          }
        </tr>
      </thead>
      <tbody>
        {orders.map((item, index) => (
          <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
            <td style={{ padding: '10px' }}>{formatDateText(item.order_date)}</td>
            <td style={{ padding: '10px' }}>{item.product_name}</td>
            <td style={{ padding: '10px' }}>{item.product_description}</td>
            <td style={{ padding: '10px', textAlign: 'center' }}>{item.manufacturer_name}</td>
            <td style={{ padding: '10px', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>
              {item.status_cat === "In Review" ? '' : `$${parseFloat(item.unit_price).toFixed(2)}`}
            </td>
            <td style={{ padding: '10px', textAlign: 'center' }}>{item.order_quantity}</td>
            <td style={{ padding: '10px', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>
              {item.status_cat === "In Review" ? '' : `$${parseFloat(item.unit_price * item.order_quantity).toFixed(2)}`}
            </td>
            <td style={{ padding: '10px', textAlign: 'center' }}>{item.status_cat}</td>
            <td style={{ padding: '10px', textAlign: 'center' }}>
              {actionsStatuses.includes(item.status_cat) && item.product_cat !== 'Reward' && item.order_code !== -2 && (
                <button 
                  className='support-button' 
                  onClick={() => handleActionsClick(item)} 
                  style={{ fontSize: 'small', width: '75px' }}
                >
                  {item.status_cat === 'Delivered' ? 'Action' : 
                  item.status_cat === 'In Dispute' ? 'Status' :
                  item.status_cat === 'Shipped' ? '' : 'Edit'}
                  {item.status_cat === 'Shipped' && (
                    <div style={{display:'flex', gap:'4px'}}>
                      <span>Track</span>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </div>
                  )}
                </button>
              )}
            </td>
            {isCheckDeliveries && 
              <td style={{ padding: '10px', textAlign: 'center' }}>
                <input 
                  type="checkbox" 
                  checked={item.delivered_manual_check} 
                  onChange={() => updateOrdersDeliveredManual(locationName, item.order_id, item.delivered_manual_check)} 
                  style={{ width: '100%' }}
                />
              </td>
            }
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default OrdersTable;
