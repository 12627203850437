import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { auth } from "../../context/Config";
import '../../assets/style/index.css'; 
import logo from '../../assets/images/login-logo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {confirmPasswordReset} from 'firebase/auth'


function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const query = useQuery()
  const [password, setPassword] = useState('')

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword)
  }

  const handleClick = () => {
    navigate('/sign-in');
  };

  return (
    <div className="login-container" style={{ display: 'flex', flexDirection: 'column' }}>
      <img src={logo} alt="Logo" className="login-logo" style={{ height: '70px', width: '400px', zIndex:1, margin:'65px' }}/> 
          
      <form
        className="log-in-form"
        style={{height: '200px'}}
        onSubmit={async e => {
          e.preventDefault()
          try {
            await resetPassword(query.get('oobCode'), password)
            toast.success('Password has been changed, you can login now.')
          } catch (error) {
            
            console.log(error.message)
          }
        }}
      >
          <input
            type='password'
            autoComplete='password'
            required
            placeholder='Enter New Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            lassName="input-field"
            style={{padding: '20px', margin:'15px', marginTop: "40px"}}
          />
        <button type='submit' className="log-in-button">
          Submit
        </button>
        <p onClick={handleClick} style={{ color: '#61C5CA', textDecoration: 'underline', cursor: 'pointer', marginTop: '20px', marginLeft: '150px' }}>
            Back to Login
        </p>
      </form>
    
      <ToastContainer />

    </div>
   
  )
}
