import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import OrdersTable from '../features/orders/OrdersTable';
import EditModal from '../features/orders/EditModal';
import DisputeModal from '../features/orders/DisputeModal';
import ReturnModal from '../features/orders/ReturnModal';
import DeliveryIssueModal from '../features/orders/DeliveryIssueModal';
import ActionModal from '../features/orders/ActionModal';
import DisputeStatusModal from '../features/orders/DisputeStatusModal';
import SupportForm from '../features/support/SupportForm'
import { updateOrdersDeliveredManual, fetchOrders, getTrackingNumber, fetchTrackingDataUrl, updateOrderDetails, updateViewer, submitOrderFeedback, submitOrderReturn, removeOrder, fetchDisputeOrderDetails} from '../utils/orders/ordersUtils';
import { fetchCheckDeliveries } from '../utils/locations/locationsUtils';
import { fuzzySearch } from '../utils/products/search';
import {closeAbortController, createAbortController, getAbortSignal} from '../utils/controller/abortUtils';
import { sortArrayByAttribute, handleSortChange as handleSortChangeUtil, getArrow } from '../utils/tables/sortUtils';
import { fetchUsername } from '../utils/users/userUtils';

const Orders = ({ locationName, call, permissionCode }) => {
  const abortControllerRef = useRef(null);  // Create a ref to hold the AbortController

  const [orderData, setOrderData] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDisputeModalOpen, setIsDisputeModalOpen] = useState(false);
  const [isCheckDeliveries, setIsCheckDeliveries] = useState(false);
  const contentRef = useRef();

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [sortAttribute, setSortAttribute] = useState('order_date');
  const [sortAsc, setSortAsc] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [username, setUsername] = useState([]);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false); 
  const [isDeliveryIssueModalOpen, setIsDeliveryIssueModaOpen] = useState(false); 
  const [isActionModalOpen, setIsActionModalOpen] = useState(false); 
  const [isDisputeStatusModalOpen, setIsDisputeStatusModalOpen] = useState(false); 
  const [disputeOrderDetails, setDisputeOrderDetails] = useState([]);

  useEffect(() => {
    createAbortController(abortControllerRef);
    const signal = getAbortSignal(abortControllerRef);

    fetchOrders(call, locationName, setOrderData);
    fetchCheckDeliveries({locationName, setIsCheckDeliveries, signal});
    fetchUsername(setUsername, signal);

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';
    
    return () => {
      closeAbortController(abortControllerRef);
    };
  }, [locationName, call]);

  const handleTrackButtonClick = async (orderId) => {
    const trackingNumber = await getTrackingNumber(call, orderId);
    if (trackingNumber) {
      const trackingDataUrl = await fetchTrackingDataUrl(call, trackingNumber);
      if (trackingDataUrl){
        window.open(trackingDataUrl, '_blank', 'noopener,noreferrer');
      }
      else {
        toast.warning('No tracking data available.')
      }
    } else {
      toast.warning('No tracking data available.');
      console.error('No tracking number found.');
    }
  };

  const handleActionsClick = async (item) => {
    setSelectedOrder(item); // Set the selected order
  
    if (item.status_cat === 'Open' || item.status_cat === 'In Review') {
      setIsEditModalOpen(true);
      updateViewer(selectedOrder?.order_id, username, call);
    } else if (['Placed', 'Shipped', 'Confirmed'].includes(item.status_cat)) {
      handleTrackButtonClick(item.order_id);
    } else if (item.status_cat === 'Delivered') {
      setIsActionModalOpen(true);
    }
    else if (item.status_cat === 'In Dispute') {
      await fetchDisputeOrderDetails(call, item.order_id, setDisputeOrderDetails )
      setIsDisputeStatusModalOpen(true);
    }
  };

   // Function to handle opening of ReturnModal from DisputeModal
   const openDisputeModal = () => {
    setIsDisputeModalOpen(true);
  };

  // Function to handle opening of ReturnModal from DisputeModal
  const openReturnModal = () => {
    setIsReturnModalOpen(true);
    setIsDisputeModalOpen(false);
    setIsActionModalOpen(false);
  };

  // Function to close ReturnModal
  const closeReturnModal = () => {
    setIsReturnModalOpen(false);
  };
  
  // Function to handle opening of ReturnModal from DisputeModal
  const openDeliveryIssueModal = () => {
    setIsDeliveryIssueModaOpen(true);
    setIsActionModalOpen(false);
  };

    // Function to handle opening of ReturnModal from DisputeModal
    const openReturnProductModal = () => {
      setIsReturnModalOpen(true);
      setIsActionModalOpen(false);
    };
  

  // Function to close ReturnModal
  const closeDeliveryIssueModal = () => {
    setIsDeliveryIssueModaOpen(false);
  };

    // Function to close ReturnModal
  const closeDisputeStatusModal = () => {
    setIsDisputeStatusModalOpen(false);
  };
  
  
  const handleUpdateDeliveredManual = async (locationName, orderId, deliveredManualCheck) => {
    await updateOrdersDeliveredManual(call, locationName, orderId, deliveredManualCheck);
    fetchOrders(call, locationName, setOrderData);
  };

  // Function to handle submitting the edited order
  const submitEdit = async (updatedOrder) => {
    await updateViewer(selectedOrder?.order_id, null, call)
    await updateOrderDetails(selectedOrder, updatedOrder, username, call); 
    setIsEditModalOpen(false);
    fetchOrders(call, locationName, setOrderData); // Refresh the orders
    toast.success('Order details updated successfully!')
  };

  const submitFeedback = async (feedbackData) => {
    await submitOrderFeedback(call, feedbackData, selectedOrder, username);
    fetchOrders(call, locationName, setOrderData); // Refresh the orders
    toast.success('Thank you for submitting the feedback!')
  }

  // Function to handle submitting the edited order
  const handleRemoveOrder = async () => {
    setIsEditModalOpen(false); // Close the modal after updating
    await removeOrder(selectedOrder, username, call);
    fetchOrders(call, locationName, setOrderData); // Refresh the orders
    toast.success('Order removed successfully!')
  };

  const submitReturn = async (returnData) => {
    await submitOrderReturn(call, returnData, selectedOrder, username);
    fetchOrders(call, locationName, setOrderData); // Refresh the orders
  }


  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }

  const statusCategories = ['Placed', 'Backorder', 'Confirmed', 'Open', 'In Review', 'Delivered', 'Shipped', 'In Dispute', 'Returned'];
  
  const openOrders = orderData
    .filter(item => statusCategories.includes(item.status_cat));
  
  const uniqueManufacturers = Array.from(new Set(openOrders.map(item => item.manufacturer_name)));
  const uniqueCategories = Array.from(new Set(openOrders.map(item => item.status_cat))); // Get unique categories

  const filteredOrders = sortArrayByAttribute(
    fuzzySearch(openOrders, searchTerm, ['product_name', 'product_description', 'manufacturer_name'])
      .filter(item =>
        (filterStatus === '' || item.status_cat.toLowerCase() === filterStatus.toLowerCase()) &&
        (selectedManufacturer === '' || item.manufacturer_name.toLowerCase() === selectedManufacturer.toLowerCase())
      ),
    sortAttribute,
    sortAsc
  );   

  return (
    <div ref={contentRef} style={{ textAlign: 'left' }}>
    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <h2 style={{ marginTop: '27px', display: 'flex', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Your Orders</h2>

      <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#87D3D7',  marginTop: '0px',  marginRight: '-5px'}}>
      <h1 style={{ fontSize: '20px' }}>?</h1>
      </button>
      {showContact && (
        <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
      )}

    </div>      
      {/* Filters */}
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <input
          type="text"
          className='select-box'
          placeholder="Search Product Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        />
        
        <select
          className='select-box'
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', fontStyle: 'italic', width: '300px' }}
        >
          <option value="">All Order Status</option>
          {uniqueCategories.map(status_cat => (
            <option key={status_cat} value={status_cat}>{status_cat}</option>
          ))}
        </select>

        <select
          className='select-box'
          value={selectedManufacturer}
          onChange={(e) => setSelectedManufacturer(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', fontStyle: 'italic', width: '300px' }}
        >
          <option value="">All Manufacturers</option>
          {uniqueManufacturers.sort().map(manufacturer => (
            <option key={manufacturer} value={manufacturer}>
              {manufacturer}
            </option>
          ))}
        </select>
      </div>

      {/* Orders Table */}
      <OrdersTable
        orders={filteredOrders}
        handleActionsClick={handleActionsClick}
        handleSortChange={handleSortChangeUtil}
        sortAttribute={sortAttribute}
        sortAsc={sortAsc}
        setSortAttribute={setSortAttribute}
        setSortAsc={setSortAsc}
        getArrow={getArrow}
        permissionCode={permissionCode}
        isCheckDeliveries={isCheckDeliveries}
        updateOrdersDeliveredManual={handleUpdateDeliveredManual}
        locationName={locationName}
      />

      <EditModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} orderDetails={selectedOrder} submitEdit={submitEdit} handleRemoveOrder ={handleRemoveOrder}/>
      <ActionModal 
        isOpen={isActionModalOpen} 
        onClose={() => setIsActionModalOpen(false)} 
        orderDetails={selectedOrder}
        openDeliveryIssueModal = {openDeliveryIssueModal}
        openReturnProductModal = {openReturnProductModal}
        submitFeedback = {submitFeedback}
      />
      <DisputeModal 
        isOpen={isDisputeModalOpen} 
        onClose={() => setIsDisputeModalOpen(false)} 
        orderDetails={selectedOrder}
        openReturnModal={openReturnModal} // Pass this function
        openDeliveryIssueModal = {openDeliveryIssueModal}
      />
      <ReturnModal 
        isOpen={isReturnModalOpen} 
        onClose={closeReturnModal} 
        orderDetails={selectedOrder}
        submitReturn={submitReturn}
      />
      <DeliveryIssueModal 
        isOpen={isDeliveryIssueModalOpen} 
        onClose={closeDeliveryIssueModal} 
        orderDetails={selectedOrder}
        submitReturn={submitReturn}
      />
      <DisputeStatusModal 
        isOpen={isDisputeStatusModalOpen} 
        onClose={closeDisputeStatusModal} 
        orderDetails={disputeOrderDetails}
      />
      <ToastContainer/>
    </div>
  );
};

export default Orders;
